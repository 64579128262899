export default {
	auth: {
		base: {
			email: 'Emailadres',
			password: 'Wachtwoord',
			register: 'Registreer',
			login: 'Log in',
			logout: 'Uitloggen',
			'my-details': 'Mijn gegevens',
			preferences: 'Mijn voorkeuren'
		},
		login: {
			forgot_password: 'Wachtwoord vergeten?',
			create_account: 'Nog geen account?',
			activated: 'Uw account is succesvol geactiveerd. U kunt nu inloggen met uw inloggegevens.',
			activated_invalid: 'Uw account is al reeds geactiveerd of uw token is verlopen.',
			user_not_found: 'Deze gebruiker is niet gevonden',
			user_not_authorized: 'Uw e-mail of wachtwoord is onjuist'
		},
		forgot_password: {
			header: 'Wachtwoord vergeten',
			success_notification:
				'Binnen enkele ogenblikken ontvangt u een e-mail met instructies om uw wachtwoord te resetten'
		},
		reset_password: {
			header: 'Nieuw wachtwoord instellen',
			success_notification: 'Uw wachtwoord is aangepast'
		},
		register: {
			header: 'Het is tijd om je aan te sluiten bij de toekomst... die vandaag begint!',
			role: 'Welke rol heb je?',
			company: 'Bedrijfsnaam',
			login: 'Inloggen',
			termsAndConditions: 'Algemene voorwaarden',
			termsAndConditionsText: 'Algemene voorwaarden tekst',
			privacyStatement: 'Privacyverklaring',
			privacyStatementText: 'Privacyverklaring tekst',
			success: 'Succes',
			successText: 'Het registreren is gelukt, kijk in de inbox van je e-mail voor een activitatielink!'
		},
		add_company: {
			header: 'Registreer je bedrijf zodat je al die fantastische functionaliteit kan gebruiken!',
			notification:
				'4shipping beoordeelt je aanvullende registratie binnen 2 werkdagen. Je wordt op de hoogte gebracht als je toegang krijgt tot het afgeschermde deel van het platform'
		},
		add_vessel: {
			header: 'Registreer je schip zodat je het vrachtaanbod kan zien!'
		}
	},
	base: {
		at: 'om',
		week: 'Week',
		country: 'Land',
		terminals: 'Terminals',
		berths: 'Ligplaatsen',
		close: 'Sluiten',
		phone_number: 'Telefoonnummer',
		email: 'E-mail',
		website: 'Website',
		opening_hours: 'Openingstijden',
		yes: 'ja',
		no: 'nee',
		subjectedTo: 'Onder voorbehoud van',
		left: 'links',
		right: 'rechts',
		open: 'Open',
		closed: 'Gesloten',
		locks: 'Sluizen',
		height: 'Hoogte',
		length: 'Lengte',
		width: 'Breedte',
		max_width: 'Max. breedte',
		phoneNumber: 'Telefoonnummer',
		address: 'Adres',
		operatingHours: 'Bedieningstijden',
		maxDimensions: 'Max. afmetingen',
		administrator: 'Beheerder',
		name: 'Naam',
		number: 'nr. {0}',
		next: 'Volgende',
		note: 'Opmerking',
		suffix_meters_short: 'm',
		suffix_hours_short: 'u',
		suffix_centimeters_short: 'cm',
		suffix_kilometers_short: 'km',
		suffix_grams: 'gram',
		suffix_hours: 'uur',
		suffix_minutes: 'min',
		suffix_minutes_short: 'm',
		suffix_days: 'dagen',
		suffix_days_short: 'd',
		suffix_day: 'dag',
		suffix_cost_per_ton: '€/ton',
		suffix_tons: 'ton',
		suffix_teu: 'TEU',
		suffix_kg: 'kg',
		suffix_tonnage: 'ton',
		suffix_cubic_meters: 'm3',
		suffix_cubic_meters_per_second: 'm3/s',
		distance: 'Afstand',
		duration: 'Duur',
		agreed: 'Akkoord',
		ok: 'Oké',
		bridges: 'Bruggen',
		allow: 'Toestaan',
		deny: 'Weigeren',
		save: 'Opslaan',
		submit: 'Verzenden',
		cancel: 'Annuleren',
		delete: 'Verwijder',
		car_scaffolding: 'Autosteigers',
		bunker_stations: 'Bunkerstations',
		water_levels: 'Waterstand',
		ports: 'Havens',
		route: 'Route',
		more: 'Meer',
		'thank-you': 'Bedankt',
		relative_to_nap: 't.o.v. NAP',
		on_date: 'op {date}',
		origin: 'Vertrekpunt',
		destination: 'Bestemming',
		today: 'Vandaag',
		table: 'Tabel',
		chart: 'Grafiek',
		roles: {
			shipper: 'Verlader',
			skipper: 'Schipper',
			logisticserviceprovider: 'Bevrachter',
			portmanager: 'Havenbeheerder',
			client: 'Opdrachtgever',
			carrier: 'Vervoerder'
		},
		compass: {
			north: 'noord',
			northeast: 'noord-oost',
			east: 'oost',
			southeast: 'zuid-oost',
			south: 'zuid',
			southwest: 'zuid-west',
			west: 'west',
			northwest: 'noord-west'
		},
		vhf: 'VHF',
		prefered: 'Gewenst',
		mandatory: 'Vereist',
		language: 'Taal',
		back: 'Terug',
		coming_soon: 'Coming soon',
		file_uploaded: 'Bestand geüpload'
	},
	notifications: {
		selectNotifications: 'Selecteer in welke gevallen u graag een e-mail wilt ontvangen:',
		newMessages: 'Bij een nieuw ongelezen bericht',
		newBidOnCargoOffer: 'Bij een nieuw bod op mijn aangeboden vracht(en)',
		cargoOfferedToVessel: 'Wanneer een vracht specifiek aan mijn schip is aangeboden',
		addedToGroup: 'Wanneer ik aan een groep wordt toegevoegd',
		relevantOffers: 'Bij nieuw gepubliceerd, voor mij relevant vrachtaanbod',
		charterGenerated: 'Wanneer er een charter voor mij klaar staat',
		charterAccepted: 'Wanneer een schipper mijn charter heeft geaccepteerd',
		charterRejected: 'Wanneer een schipper mijn charter heeft afgewezen',
		cargoOfferedToBroker: 'Wanneer een verlader een vracht heeft aangeboden',
		savedSuccesfully: 'Uw wijzigingen zijn verwerkt'
	},
	charterPreferences: {
		editCharterPreferences: 'Bekijk en wijzig uw charter instellingen:',
		editLogo: 'Ik wil mijn logo wijzigen',
		editDisclaimer: 'Ik wil mijn disclaimer wijzigen',
		disclaimer: 'Disclaimer',
		newLogo: 'Nieuw logo toevoegen'
	},
	attributes: {
		selectPreferences: 'Geef uw voorkeuren aan',
		selectDetails: 'Geef aanvullende informatie door',
		groups: {
			hold: 'Ruim(en)',
			general: 'Algemeen',
			certificate: 'Certificaten/vergunningen',
			preferences: 'Voorkeuren',
			ungrouped: 'Overige'
		}
	},
	ais_position: {
		title: 'Mogen wij je AIS positie weten?',
		description:
			'Om je beter van dienst te zijn hebben wij toegang nodig tot je AIS positie. Hiervoor hebben wij het MMSI Nummer nodig.',
		mmsi_number: 'MMSI Nummer',
		approve: 'Ik geef 4Shipping toegang tot mijn AIS Positie.'
	},
	contact: {
		improvement: 'Feedback / wens voor verbetering',
		bug_partial_blocking: 'Bug die mij gedeeltelijk blokkeert',
		bug_fully_blocking: 'Bug die mij volledig blokkeert',
		explain_functionality: 'Vraag over functionaliteit',
		title: 'Contact',
		description: 'Via dit formulier kunt u een vraag stellen of feedback sturen aan 4shipping.',
		label: 'Vraag of feedback',
		label_long: 'Vraag/opmerking aan 4shipping?',
		success: 'Het versturen van je vraag/opmerking is gelukt.',
		error: 'Er is iets fout gegaan met het versturen van de vraag/opmerking. Probeer het later opnieuw.'
	},
	errors: {
		generic: 'Er is iets misgegaan in de communicatie met de server. Probeer het later opnieuw.',
		forbidden: 'U heeft geen rechten om deze pagina te bekijken',
		unknown: 'Er is een onbekende fout opgetreden.',
		not_found: 'De opgevraagde pagina kan niet worden gevonden.',
		form_errors: 'Foutmelding(en):',
		upload_errors: 'Er is iets misgegaan bij het uploaden van het bestand. ',
		download_errors: 'Er is iets misgegaan tijdens het downloaden van het bestand. Probeer het later opnieuw.',
		active_pending: {
			header: 'Uw aanvullende registratie is in behandeling',
			body: 'Hierover krijgt u bericht'
		},
		redirect_inactive: 'Er is aanvullende informatie nodig met betrekking tot uw registratie.',
		chunkLoadError: 'Er is een nieuwe versie beschikbaar, de applicatie moet worden herladen.'
	},
	login: {
		header: 'Beta versie',
		email: 'Emailadres',
		password: 'Wachtwoord',
		button: 'Log in',
		register: 'Registreer',
		forgot_password: 'Wachtwoord vergeten?',
		create_account: 'Nog geen account?'
	},
	map: {
		showOptions: 'Andere zoekopties weergeven',
		hideOptions: 'Andere zoekopties verbergen',
		vesselLength: 'Schip lengte',
		vesselWidth: 'Schip breedte',
		vesselHeight: 'Schip hoogte',
		maxLoadCapacity: 'Max. laadvermogen',
		loadedQuantity: 'Ingeladen tonnen',
		startDate: 'Vertrek tijdstip',
		draught: 'Diepgang',
		exploitation: 'Exploitatie',
		withMultipleRoutes: 'Alternatieve routes ook tonen',
		withAspect: '{0} worden meegenomen in berekeningen',
		operatingTimes: 'Bedieningstijden',
		closures: 'Stremmingen',
		tides: 'Getijden',
		withEmptyReturn: 'Incl. leeg terug',
		loadingTimeHours: 'Incl. laadtijd',
		unloadingTimeHours: 'Incl. lostijd',
		routeType: {
			title: 'Route type',
			best_route: 'beste route',
			whole_network: 'schip 38*5*2*1.5 (hele netwerk)',
			smaller_ship: 'schip 20% kleiner (L.B.H.D)',
			shortest: 'kortste route (<km)',
			without_ftm_messages: 'zonder FTM berichten',
			without_operating_times: 'zonder bedieningstijden (24/7)',
			start_later: 'start 3u later'
		},
		routeOptimizationType: {
			title: 'Route optimalisatie',
			km: 'Kilometers',
			time: 'Tijd',
			cost_per_ton: 'Kosten per ton'
		},
		feedback: 'Geef feedback over deze route',
		message: 'Klik op verschillende routes om de details te bekijken.',
		categories: {
			header: 'Verkeersinformatie voor de binnenvaart'
		},
		directions: {
			'waterway-dimensions': 'De afmetingen van je schip zijn te groot of te breed voor dit deel van de route',
			suffix_number_of_locks: 'Sluizen',
			no_route_found: 'Er is geen route mogelijk tussen dit vertrekpunt en bestemming',
			current_location: 'Huidige locatie ({vessel})',
			current_location_geolocation: 'Huidige locatie (apparaat)',
			waterway_dimensions: {
				length: 'De afmeting van uw schip is te lang voor dit deel van de route. Aantal meters te lang: ',
				width: 'De afmeting van uw schip is te breed voor dit deel van de route. Aantal meters te breed: ',
				draught: 'De afmeting van uw schip is te diep voor dit deel van de route. Aantal meters te diep: ',
				'waterway-dimensions': 'De afmeting van uw schip is te groot of te breed voor dit deel van de route'
			},
			limits: 'Maximale afmetingen op deze route:',
			limits_ww: 'Vaarwegbeperking:',
			limits_kw: 'Kunstwerkbeperking:',
			limits_with_draught: '{length}m lang, {width}m breed, {draught}m diep',
			limits_with_height: '{length}m lang, {width}m breed, {height}m hoog',
			limits_length: '{quantity}m lang',
			limits_width: '{quantity}m breed',
			limits_height: '{quantity}m hoog',
			limits_draught: '{quantity}m diep',
			costs: {
				title: 'Kosten',
				total: 'Totale kosten:',
				costsPerTon: 'Kosten per ton:',
				fuel: 'Brandstofkosten:',
				personnel: 'Personeelskosten:',
				material: 'Materiaalkosten:',
				port: 'Havengeld kosten:',
				disclaimer:
					'Inzichten zijn een indicatie. Er kunnen geen rechten ontleend worden aan deze inzichten. 4shipping accepteert geen aansprakelijkheid.'
			}
		},
		'inaccessible-route': 'Let op! De route is (gedeeltelijk) ontoegankelijk'
	},
	navigation: {
		beta: 'Beta',
		dashboard: 'Overzicht',
		map: 'Kaart',
		cargo: 'Vracht',
		capacityRadar: 'Capaciteit',
		relation: 'Relatie',
		transport: 'Transport',
		more: 'Meer',
		messages: 'Berichten',
		groups: 'Groepen',
		fleet: 'Vloot',
		dashboardInsights: 'Inzicht',
		intermodal_booking: 'Boek container',
		loading_bill: 'Maak laadbon',
		financial: 'Financieel',
		terminals: 'Terminals',
		depot: 'Depot'
	},
	terminals: {
		harbour_title: 'Haven/Kanaal/Rivier',
		harbour: 'Haven/Kanaal',
		harbour_number: 'Havennummer',
		location_code: 'UN locatie',
		transfer_type_title: 'Overslag type',
		can_transfer_container: 'Container overslag',
		can_store_container: 'Container opslag',
		can_transfer_bulk: 'Bulk overslag',
		can_store_bulk: 'Bulk opslag',
		can_transfer_piece_goods: 'Stukgoed overslag',
		can_store_piece_goods: 'Stukgoed opslag',
		quay_length: 'Kadelengte',
		specifications_title: 'Specificaties',
		user_managed: 'De data van deze terminal wordt beheerd door uw bedrijf.',
		comments: 'Opmerkingen',
		is_public: 'Geregistreerd'
	},
	cargo: {
		title: 'Vrachtaanbod',
		search: 'Zoeken...',
		drybulk: 'Bulk',
		containers: 'Containers',
		liquid: 'Vloeistof',
		breakbulk: 'Stukgoed',
		clean_slate: {
			title: 'Vracht aanbieden',
			headline: 'Krijg inzicht in transportopties',
			add_cargo_button: 'Nieuwe vracht toevoegen',
			text_main: 'Start met het aanmaken van vrachten en het inplannen of tenderen.',
			text_api: 'Voortaan automatisch vracht aanbieden? Dat kan via onze API.'
		},
		price_units: {
			block: 'En bloc',
			per_ton: 'ton',
			per_teu: 'TEU',
			per_cubic_meter: 'm3',
			ton: 'ton',
			per_day: 'per dag',
			per_hour: 'per uur',
			per_week: 'per week',
			per_piece: 'Stuk'
		},
		options: {
			example: 'Bijvoorbeeld',
			estimated_freight_price: 'Geschatte vrachtprijs',
			estimated_commission: 'Geschatte provisie',
			estimated_total_costs: 'Geschatte totaalkosten',
			estimated_total_revenue: 'Geschatte totale inkomsten',
			has_gasoil_addition: 'Brandstoftoeslag',
			fuel_index: {
				label: 'Index',
				kbn: 'KBN',
				pjk: 'PJK',
				fod: 'FOD Economie'
			},
			berth_fee_quantity: {
				ton: 'Ton',
				cubic: 'Kuub',
				per_piece: 'Stuks'
			},
			berth_fee_time: {
				per_hour: 'Per uur',
				per_day: 'Per dag',
				per_half_day: 'Per halve dag',
				per_eight_hours: 'Per acht uur',
				per_six_hours: 'Per zes uur'
			},
			custom_berth_fee: {
				label: 'Liggeld vergoeding gecombineerd',
				load_label: 'Liggeld vergoeding bij laden',
				unload_label: 'Liggeld vergoeding bij lossen',
				amount: 'Toeslag',
				quantity_unit: 'Hoeveelheid',
				time: 'Tijd'
			},
			measured_time: {
				label: 'Tijdstip',
				loading_day: 'Dag van beladen',
				unloading_day: 'Dag van leegkomst',
				monday: 'Maandag',
				average_last_month: 'Gemiddelde laatste maand',
				average_last_half_month: 'Gemiddelde laatste halve maand',
				specific_date: 'Specifieke datum',
				first_day_of_month: 'Eerste dag van de maand'
			},
			payment_term_condition_options: {
				upon_receipt_of_invoice_and_freight_documents: 'Na ontvangst factuur en vrachtdocumenten',
				after_invoice_date: 'Na factuurdatum',
				upon_unloading: 'Na lossing',
				upon_loading: 'Na belading',
				after_bill_of_lading_date: 'Na vrachtbrief datum'
			},
			calculation_method: {
				label: 'Toeslag berekenen over',
				loading_weight: 'Ingeladen hoeveelheid',
				unloading_weight: 'Uitgeloste hoeveelheid',
				price: 'Vrachtprijs'
			},
			threshold: 'Vanaf',
			step_size: 'Stapgrootte',
			amount_per_step: 'Toeslag/stap',
			certificationIsBRL: 'BRL',
			delivery_terms: 'Leveringsvoorwaarden',
			applicabilityClaim: 'Toepasbaarheid',
			approximateCargoSize: 'Circa',
			addition_height_meters: 'Pegelstand in meters',
			addition_height: 'Pegelstand',
			addition_percentage: 'Percentage',
			end_date_travel_period_applies: 'Er is een einde reisperiode van toepassing',
			end_date_travel_period: 'Einde reisperiode',
			end_date_rental_period_applies: 'Er is een einde huurperiode van toepassing',
			end_date_rental_period: 'Einde huurperiode',
			provide_loading_time: 'Tijdstip opgeven',
			is_reporting_date: 'Melden op',
			loading_time: 'Tijdstip laden',
			berth_fee: 'Liggeld',
			load_time_condition: 'Laadtijd condities',
			block: 'En bloc',
			cargo_hold_floor: 'Laadruimvloer',
			cargo_hold_floor_demand: 'Laadruimvloer eis',
			cargo_insured_by: 'Lading verzekerd bij:',
			charter_type: 'Charter type',
			combined: 'Gecombineerd',
			comments: 'Algemene opmerkingen',
			price_comments: 'Prijs opmerkingen',
			cover_cargo_while_shipping: 'Afgedekt vervoeren',
			cubic: 'Kuub',
			custom_dossier_number: 'Dossier nummer',
			end: 'Maximum',
			extra_options: 'Extra opties',
			financial: 'Financieel',
			high_tide_costs_apply: 'HWZ (hoogwatertoeslag)',
			iam_expert: 'Ik ben expert',
			enable_charter_details: 'Genereer charter (voor vervoerder)',
			charter_details: 'Charter details',
			close_charter_details: 'Charter details verbergen',
			enable_order_details: 'Genereer opdrachtbevestiging (voor opdrachtgever)',
			order_details: 'Opdrachtbevestiging gegevens',
			client: 'Opdrachtgever',
			client_order_reference: '(inkoop) referentie van de klant',
			client_reference: 'Klant referentie',
			client_order_reference_short: '(inkoop) referentie',
			product_remark: 'Opmerking',
			via_broker: 'Via bevrachter',
			carrier: 'Vervoerder',
			ice_costs: 'Stremming/ijstoeslag',
			is_certification_required: 'Certificerings eis',
			has_last_transported_cargos: 'Laatst vervoerde producten',
			has_legal_basis: 'Transportbasis',
			is_insurance_required: 'Verzekering schip vereist',
			is_subjected_to_empty_vessel: 'Onder voorbehoud van voorgaande reis',
			has_gasoil_surcharge: 'Gasolie toeslag',
			keep_it_simple: 'Ik hou het eenvoudig',
			legal_basis: 'Transportbasis',
			load_and_unload_time: 'Laad- en lostijd',
			load_berth_fee: 'Overliggeld laden',
			unload_time_condition: 'Lostijd condities',
			load_unload_time_condition: 'Laad- en lostijd condities',
			load_on: 'Laden op',
			load_time: 'Laadtijd',
			load_unload_conditions: 'Laad/los-condities',
			loaded: 'Ingeladen tonnen',
			loadedQuantity: 'Ingeladen hoeveelheid',
			loading_place: 'Laadplaats',
			low_tide_costs_apply: 'KWZ (laagwatertoeslag)',
			low_tide_addition_time: 'beladingsdag 5.00 uur',
			payment_over: 'Betaling over',
			per_unit: 'Per eenheid',
			price: 'Prijs',
			price_validation: 'Het prijsveld is verplicht',
			product: 'Product',
			provision: 'Provisie',
			rental: 'Huur',
			separately: 'Afzonderlijk',
			not_applicable: 'Niet van toepassing',
			shipper: 'Verlader',
			makeQuotation: 'Offerte',
			skipper: 'Schipper',
			fuel_costs: 'Brandstofkosten betaald door:',
			port_costs: 'Havengelden betaald door:',
			start: 'Minimum',
			'submit-cargo': 'Transporteur kiezen',
			'submit-concept': 'Opslaan als concept',
			target_price: '(Richt)prijs',
			ton: 'Ton',
			piece: 'Stuks',
			to: 'Tot',
			transfer_time: 'Minimumvaartijd',
			unload_berth_fee: 'Overliggeld lossen',
			payment_term_condition: 'Betalingsconditie',
			unload_on: 'Lossen op',
			unload_on_sundays: 'Zon- feestdagen varen/laden/lossen',
			unload_on_sundays_not_required: 'Niet verplicht',
			unload_on_sundays_required: 'Verplicht',
			unload_time: 'Lostijd',
			unloaded: 'Uitgeloste tonnen',
			unloadedQuantity: 'Uitgeloste hoeveelheid',
			unloading_place: 'Losplaats',
			container_status: {
				full: 'Vol',
				empty: 'Leeg',
				mix: 'Mix',
				mixClarified: 'Vol en leeg'
			},
			must_have_reefer_connection: 'Reefer aansluitingen',
			reefer_number: 'Aantal reefer aansluitingen',
			must_have_adn: 'ADN certificering (voor gevaarlijke stoffen)',
			amount_of_cones: 'Aantal cones',
			max_amount_layers: 'Max. aantal lagen in het schip',
			is_subjected_to_stability: 'Stabiliteit, tonnage (op waterstand) en weersomstandigheden',
			number_of_terminals: 'Aantal terminals',
			start_terminals_quantity: 'Aantal terminals bij laadlocatie',
			destination_terminals_quantity: 'Aantal terminals bij loslocatie',
			load_on_water_level: 'Laden op waterstand',
			load_up_to_amount: 'Volle lading tot aangegeven hoeveelheid',
			teu_quantity_min: 'Minimum aantal',
			teu_quantity_max: 'Maximum aantal',
			mandatory_field: 'Dit is een verplicht veld',
			no_unload_date: 'Geen losdatum? Dan meldt de schipper zich bij aankomst',
			up_to: 'tot',
			termOfPaymentDays: {
				label: 'Betalingstermijn',
				unit: 'dagen'
			},
			productLastTransported: '1e product laatst vervoerd',
			productSecondLastTransported: '2e product laatst vervoerd ',
			productThirdLastTransported: '3e product laatst vervoerd',
			holdCleaningCode: 'Reinigingscode ruim',
			copy_charter_details: 'Kopiëer gegevens van charterdetails',
			required_draughts: {
				title: 'Te varen diepgangen',
				pegel: 'Pegel',
				draught: 'diepgang (m)',
				add: 'Diepgang toevoegen'
			},
			or: 'OF',
			loadTimeMethod: {
				label: 'Laden',
				time: 'Tijd',
				condition: 'Conditie',
				guaranteed: 'Garantie vol'
			},
			unloadTimeMethod: {
				label: 'Lossen',
				time: 'Tijd',
				condition: 'Conditie',
				guaranteed: 'Garantie leeg'
			},
			before_leave: 'Let op! Deze vracht is nog niet opgeslagen. Weet u zeker dat u deze pagina wilt verlaten?',
			delivery_method: 'Leverwijze'
		},
		routeplanner: {
			cost_unit: {
				ton: '€/ton',
				teu: '€/teu'
			}
		},
		compare_transport_options: 'Bekijk transportopties',
		cost_per_ton: 'Kosten',
		co2_output: 'CO2',
		nox_output: 'NOx',
		fuel_usage: 'Fuel',
		pm_output: 'PM',
		saved_successfully: 'Wij brengen u zo snel mogelijk in contact met een vervoerder.',
		projects: {
			vesselTransport: 'Binnenvaart',
			truckTransport: 'Truck',
			header: 'Project aanmaken',
			headerEdit: 'Project wijzigen',
			submit: 'Vracht template maken',
			create: 'Project aanmaken',
			edit: 'Ga verder',
			delete: 'Verwijder',
			projectName: 'Eigen referentie / projectnaam',
			startDate: 'Van',
			endDate: 'Tot',
			teu_quantity_min: 'Minimum aantal teu',
			teu_quantity_max: 'Maximum aantal teu',
			tonnage_min: 'Minimum tonnage',
			tonnage_max: 'Maximum tonnage',
			saved_successfully: 'Uw project is aangemaakt. Ga verder met het aanmaken van een blueprint.',
			edited_successfully: 'Uw project is gewijzigd.',
			blueprint: {
				title: 'Maak een conceptvracht',
				submit: 'Template opslaan'
			},
			details: {
				title: 'Project',
				projectDetails: 'Project details',
				transports: 'Transporten',
				product: 'Product',
				cargoSize: 'Vrachtgrootte',
				projectPeriod: 'Project termijn',
				shippedTonnage: 'Verscheepte tonnage',
				shippedTeu: 'Verscheepte teu',
				edit: 'Wijzig',
				offer: 'Transport inplannen',
				volume: 'Volume',
				route: 'Route',
				projectName: 'Project referentie',
				receivedReplies: 'Reacties ontvangen',
				noReplies: 'Nog geen reacties'
			}
		},
		offers: {
			search: 'Zoeken...',
			header: 'Vind de beste vracht',
			'no-offers': 'Er zijn op dit moment geen openbare vrachten beschikbaar.',
			'no-historic-offers': 'U heeft nog niet eerder op een vracht gereageerd.',
			show_historic_offers: 'Toon historische vrachten.',
			'vessel-empty-header': {
				title: 'Registreer leegkomst van een schip',
				title_skipper: 'Meld je leeg zodat we nieuwe vrachten kunnen vinden',
				edit: 'Leegmelding wijzigen'
			},
			sections: {
				order: 'Aanvragen',
				tender: 'Tender',
				actions: 'Acties'
			},
			headers: {
				projectName: 'Projectnaam',
				dossierNumber: 'Dossier nummer',
				client: 'Klant',
				product: 'Product',
				quantity: 'Hoeveelheid',
				loadingLocation: 'Laadlocatie',
				unloadingLocation: 'Loslocatie',
				loadingTime: 'Laden',
				unloadingTime: 'Lossen',
				offerUntil: 'Tender open',
				offerInterest: 'Interesse',
				actions: 'Acties'
			},
			project: {
				noResults: 'Dit project heeft momenteel geen vracht aanvragen.'
			}
		},
		offered: {
			projects: 'Projecten',
			header: 'Vracht',
			add_cargo_button: 'Vracht aanmaken',
			delete_cargo_button: 'Vracht verwijderen',
			copy_cargo_button: 'Vracht dupliceren',
			continue_cargo_button: 'Ga verder met vorige vracht',
			show_historic_cargo: 'Toon historische vrachten',
			no_historic_cargo: 'U heeft nog geen verlopen vrachten.',
			own: 'Eigen vracht',
			external: 'Externe vracht',
			'capacity-radar': 'Capaciteitsradar'
		},
		capacityRadar: {
			clean_slate: {
				headline: 'Lidmaatschap nodig',
				request: 'Wil je schepen zoeken op basis van leegemelding of scheepseigenschappen?',
				contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
				contact_details: 'info@4shipping.com of +31 85 40 189 40 of gebruik het contact formulier in de app.'
			},
			table: {
				sections: {
					ship: 'Schip',
					empty: 'Leegkomst',
					crm: 'CRM',
					actions: 'Acties'
				},
				headers: {
					vesselName: 'Scheepsnaam',
					tonnage: 'Tonnage',
					capacityCubicMeters: 'm3',
					length: 'Lengte',
					width: 'Breedte',
					date: 'Datum',
					location: 'Locatie',
					remark: 'Opmerking',
					spotmarket: 'Spotmarkt',
					via: 'Via',
					lastContact: 'Laatst contact',
					actions: 'Acties',
					geographicAreas: 'Geografisch gebied'
				}
			},
			filterDialog: {
				title: 'Filter potentiële schepen',
				location: 'Locatie',
				geographicArea: 'Geografisch gebied',
				radius: 'Radius (km)',
				width: 'Breedte',
				length: 'Lengte',
				tonnage: 'Tonnage',
				hasEmpty: 'Heeft een leegmelding'
			},
			noResults: 'Er zijn geen leegmeldingen die voldoen aan de zoekcriteria.',
			actions: {
				showContactCard: 'Contactkaart',
				removeEmpty: 'Leegmelding verwijderen',
				editEmpty: 'Leegmelding wijzigen'
			}
		},
		offer: {
			'no-bids': 'Geen bieding',
			'find-empty-ship': 'Vind leeg schip',
			message: 'Bericht(en)',
			'offer-interest': 'Belverzoek',
			'ship-offered': 'Aanbieding',
			'no-price': 'Zonder prijsopgave',
			draft: 'Concept'
		},
		clients: {
			title: 'Klant',
			placeholder: 'Kies een klant'
		},
		publication: {
			has_charter: 'Er wordt een charter gegenereerd.',
			no_charter: 'Er wordt geen charter gegenereerd.',
			has_order: 'Er wordt een opdrachtbevestiging gegenereerd.',
			no_order: 'Er wordt geen opdrachtbevestiging gegenereerd.',
			cannot_tender_no_terms: 'Tenderen is niet mogelijk zonder charter voorwaarden.',
			saved_successfully: 'Het publiceren van uw vrachtaanbod is gelukt!',
			planned_successfully: 'Uw vracht is ingepland!',
			edit_publication_date: 'Wijzig publicatie datum',
			date_editted_succesfully: 'De publicatie einddatum van uw vracht is gewijzigd!',
			bidReviewNotRequired: 'Valt onder raamcontract',
			title: 'Aanbieden',
			spot_market: 'Directe schippers (spotmarkt)',
			all: 'Allemaal',
			favourite_group: 'Favoriete groep',
			favourite_group_label: 'Dit wordt alleen gedeeld met geregistreerde vaartuigen.',
			canCollaborate:
				'U kunt met deze schipper samenwerken met het platform omdat deze schipper een account heeft.',
			via_broker: 'Via bevrachter',
			viaBrokerRegisteredVessel:
				'U plant een geregistreerd schip in via een bevrachter. Omdat dit via een bevrachter gaat delen we niets met de schipper (wat normaal gesproken bij geregistreerde schepen wel gebeurt).',
			brokers: {
				title: 'Logistiek dienstverleners',
				all: 'Allemaal',
				favourite_group: 'Favoriete groep',
				none: 'Niet aanbieden',
				allPanel: {
					collapsed: 'Toon logistiek dienstverleners',
					expanded: 'Verberg logistiek dienstverleners',
					subtitle: 'Logistiek dienstverleners'
				},
				favourite: {
					no_membership: 'Dit is pro-functionaliteit. Sluit een abonnement af via {0}.'
				}
			},
			skippers: {
				title: 'Directe schippers (spotmarkt)',
				all: 'Allemaal',
				favourite_group: 'Favoriete groep',
				none: 'Niet aanbieden',
				add: 'Toevoegen'
			},
			no_group: 'Je hebt nog geen favoriete groepen. Klik hier om je eerste groep aan te maken.',
			phone_number: 'Mijn telefoonnummer tonen bij dit aanbod',
			offer_until: 'Aanbieden tot:',
			offer_now: 'Bied nu aan',
			tender_now: 'Tender publiceren',
			plan_now: 'Plan in',
			create_contract_now: 'Toon contract voorbeeld',
			favourite_group_placeholder: 'Favoriete groep kiezen',
			fleet_placeholder: 'Kies een schip uit uw vloot',
			colleague_placeholder: 'Kies een collega',
			client_placeholder: 'Kies een klant',
			select_vessel: 'Selecteer schip',
			specific_vessel: 'Bekend schip inplannen',
			specific_broker_or_vessel: 'Bekende bevrachter of schip inplannen',
			fleet_vessel: 'Schip uit eigen vloot kiezen',
			find_vessel: 'Zoek op (scheeps)naam',
			find_name: 'Zoek op naam',
			no_results: 'Geen resultaten gevonden',
			place_search: 'Voer een zoekterm in om schepen te zoeken',
			place_longer_search: 'Voer een zoekterm in van minimaal 3 letters om schepen te zoeken',
			place_search_brokers: 'Voer een zoekterm in om bevrachters te zoeken',
			place_longer_search_brokers: 'Voer een zoekterm in van minimaal 3 letters om bevrachters te zoeken',
			click_to_create: 'Een nieuw schip maken',
			vessels_hint: 'Dit kunnen uitsluitend geregistreerde schepen zijn met een eigen account.',
			menu: {
				schedule: 'Inplannen',
				tender: 'Tenderen'
			},
			favouriteGroupCreatorTitle: {
				brokers: 'Nieuwe favoriete bevrachtersgroep',
				vessels: 'Nieuwe favoriete groep'
			},
			before_leave: 'Let op! Deze vracht is nog niet definitief. Weet u zeker dat u deze pagina wilt verlaten?'
		},
		show: {
			header: 'Vrachtaanbod',
			expires_at: 'Verloopt over',
			expired: 'Dit aanbod is verlopen.',
			has_transport: 'Er is al een transport aangemaakt.',
			loaded_weight: 'Ingeladen gewicht',
			unloaded_weight: 'Uitgelost gewicht',
			cargo_by: 'Lading door',
			not_based_on_emptiness: 'Niet o.v.b.h. van leegkomst',
			based_on_emptiness: 'O.v.b.h. van leegkomst',
			incl_sundays: 'incl. Zon-/feestdagen',
			without_sundays: 'Zon-/feestdagen niet vereist',
			price: '(Richt)prijs',
			address: 'Adres',
			city: 'Plaats',
			comments: 'Opmerkingen',
			cover_sheet: 'Voorblad',
			client: 'Klant',
			creator: 'Opdrachtgever',
			condition_preference_loading: 'Laadcondities',
			condition_preference_unloading: 'Loscondities',
			conditions_not_applicable: 'Niet van toepassing',
			load: 'Laden',
			load_period: 'Laadtermijn',
			load_time: 'Laadtijd',
			load_time_guaranteed_at: 'Garantie vol',
			unload_time_guaranteed_at: 'Garantie leeg',
			load_berth_fee: 'Overliggeld laden',
			unload: 'Lossen',
			unload_period: 'Lostermijn',
			unload_time: 'Lostijd',
			unload_berth_fee: 'Overliggeld lossen',
			load_up_to_amount: 'Een volle lading tot',
			port_costs: 'Havengelden',
			print: 'Afdrukken',
			channel_costs: 'Kanaalkosten',
			fuel_costs: 'Brandstofkosten',
			working_days: 'Werkdagen',
			affordable: 'Betaalbaar',
			insurance: 'Verzekering',
			available: 'Beschikbaar',
			required: 'Vereist',
			name: 'Naam',
			loaded_quantity: 'Ingeladen hoeveelheid',
			unloaded_quantity: 'Uitgeloste hoeveelheid',
			delete: 'Verwijder',
			schedule: 'Inplannen',
			transfer_time: 'Vereiste vaartijd',
			commission_percentage: 'Provisie',
			commission_percentage_extra: 'geen',
			report_upon_arrival: 'Melden bij aankomst',
			edit_date: 'Wijzig datum',
			edit_cargo: 'Wijzig',
			edit_expiry_date: 'Wijzig de eind datum',
			error_has_invoice: 'Het transport heeft al een afgesloten factuur en kan niet worden verwijderd.',
			end_date_rental_or_travel: 'Einde reis/huur',
			skipper_exclusive_specific_vessel: 'Exclusief voor u!',
			skipper_exclusive_favourite_skippers: 'Exclusief voor u en enkele anderen!',
			skipper_exclusive_favourite_brokers: 'Exclusief voor u en enkele anderen!',
			shipper_exclusive_specific_vessel: 'Aangeboden aan specifiek schip',
			shipper_exclusive_favourite_skippers: 'Aangeboden aan favoriete groep',
			skipper_exclusive_logistic_service_providers: 'Exclusief voor u!',
			shipper_exclusive_logistic_service_providers: 'Aangeboden aan bevrachter',
			skipper: 'Schipper',
			vesselName: 'Scheepsnaam',
			gasoil_addition_summary:
				'{index} ({moment}) vanaf {threshold}, {amount} per stap (van {stepSize}) over {method}',
			copy_cargo: 'Kopieer vracht'
		},
		react: {
			bid: 'Bieden',
			call: 'Bellen',
			message: 'Bericht',
			submit: 'Plaats bieding',
			make_definitive: 'Maak definitief',
			submitProposal: 'Stel dit schip voor',
			edit: 'Pas aan',
			deleteConfirmation: 'Weet je zeker dat je het geselecteerde bod wil verwijderen?',
			block: 'En bloc',
			per_ton: '€ / ton',
			per_day: '€ / dag',
			bidPrice: 'Voor welke prijs',
			comments: 'Opmerkingen',
			success: 'Uw bieding is succesvol geplaatst.',
			edit_success: 'Uw bieding is succesvol aangepast.',
			please_dont_call: 'Schipper wil niet direct gebeld worden. Stuur een bericht!',
			not_available: 'Helaas niet beschikbaar.',
			enter_your_message: 'Typ je bericht',
			message_send: 'Het bericht is succesvol verstuurd.',
			show_phonenumber: 'Toon telefoonnummer + verstuur melding',
			we_inform_user: '(Wij informeren hem/haar dan over uw interesse)',
			minExpected: 'Min',
			maxExpected: 'Max',
			expectedAvailability: 'Verwacht per',
			isAccessibleForSkipper: 'Geef schipper toegang tot vracht details en documenten',
			title: 'Doe een aanbod'
		},
		details: {
			'all-replies': 'Reacties',
			negotiations: 'Onderhandelingen',
			cargo: 'Vracht',
			clientDossier: 'Klant dossier',
			ownDossier: 'Eigen dossier',
			overview: 'Vracht details',
			dossierNumber: 'Dossiernummer:',
			clientName: 'Klant:'
		},
		replies: {
			header: 'Reacties',
			offerInterests: 'Bellen',
			messages: 'Bericht',
			'no-replies':
				'Zodra er reacties zijn, verschijnen deze hier en word je op de hoogte gebracht via een bericht.',
			'no-replies-historic': 'Dit aanbod is verlopen zonder reacties.',
			'no-replies-skipper':
				'Je hebt nog niet gereageerd op deze vracht. Klik op vracht om een bod uit te brengen of contact op te nemen.',
			'definitive-with-vessel': 'Maak het transport definitief met een specifiek schip',
			'plan-now': 'Plan direct in',
			'view-empty-vessel-header': 'Zoek zelf beschikbare schepen',
			'view-empty-vessels': 'Bekijk lege schepen',
			'charter-ready-skipper': 'Beoordeel de charter',
			'charter-rejected-skipper': 'U heeft de charter afgewezen',
			'charter-accepted-skipper': 'U heeft de charter geaccepteerd',
			'charter-ready-shipper': 'U heeft een charter gegenereerd',
			'charter-accepted-shipper': 'De schipper heeft uw charter geaccepteerd',
			'charter-rejected-shipper': 'De schipper heeft uw charter afgewezen',
			'bid-made': 'U heeft een bod uitgebracht',
			'phone-request': 'U wilt dit telefonisch afhandelen',
			'message-sent': 'U heeft een bericht gestuurd',
			'unread-message': 'U heeft een nieuw, ongelezen bericht',
			'open-messages': 'Open berichtoverzicht',
			'wait-for-approval': 'Wacht op akkoord'
		},
		reply: {
			'header-offerInterests': 'Bellen',
			'header-bids': 'Bieding',
			'header-messages': 'Bericht',
			tonnage_extra: '(op maximale diepgang)',
			date_of_bid: 'bieding gedaan',
			date_of_reply: 'is deze reactie geplaatst',
			expected_tonnage: 'verwacht ik mee te kunnen nemen',
			date_of_loading: 'verwacht ik te kunnen laden',
			preview_charter: 'Definitief + contract maken',
			make_definitive: 'Definitief maken',
			make_charter: 'Genereer charter',
			accepted_without_charter: 'Voorstel geaccepteerd',
			up_to: 'tot'
		},
		empty_vessels: {
			header: 'Beschikbare schepen',
			'empty-at-extra': '(verwachte leegkomst)',
			message_sent: 'Uw bericht is verzonden',
			'is-anonymous': 'Deze leegmelding is anoniem.',
			'create-cargo-for-contact': 'Maak een vracht aan om in contact te kunnen komen met deze schipper.'
		},
		preview_charter: {
			header: 'Charter / Opdrachtbevestiging',
			charter: 'Charter',
			edit: 'Pas aan',
			finalise: 'Inplannen',
			composeMessage: 'Bericht opstellen',
			planWithoutMessage: 'Inplannen zonder bericht',
			download_email_template: 'Download concept email',
			accepted: 'De vracht is ingepland.',
			accepted_mail_sent: 'De vracht is ingepland en de contract(en) zijn verstuurd.',
			generated_and_downloaded_template: 'De charter is gegenereerd en kan worden verstuurd naar de schipper.',
			order: 'Opdrachtbevestiging',
			quotation: 'Offerte'
		},
		preview_quotation: {
			header: 'Offerte',
			edit: 'Pas aan',
			sent: 'De offerte is verstuurd.',
			createWithoutSend: 'Maak offerte aan zonder te versturen',
			composeMessage: 'Bericht opstellen',
			accepted: 'De offerte is aangemaakt.',
			accepted_mail_sent: 'De offerte is aangemaakt en verstuurd.'
		},
		mark_vessel_empty: {
			date: 'Datum: ',
			location: 'Locatie: ',
			agreeOnDeletion: 'Bestaande lege aankomstregistratie van dit vaartuig wordt verwijderd.',
			anonymous: 'Leegmelding anoniem',
			empty_expires_at_label: 'Leegkomstmelding wordt automatisch ingetrokken op:',
			notify_shippers: 'Meld leegkomst aan opdrachtgevers',
			public: 'Leegmelding openbaar',
			search: 'Zoek',
			register: 'Registreer',
			edit: 'Pas aan',
			revoke: 'Leegmelding intrekken',
			search_placeholder: 'Zoek op terminal of plaats',
			success_notification: 'Leegmelding aangemaakt',
			remark: 'Opmerking'
		},
		accept_charter: {
			header: 'Charter accepteren',
			decline: 'Weigeren',
			decline_reason: 'reden',
			decline_placeholder: 'Geef een reden...',
			accept: 'Accepteren',
			accepted: 'De charter is geaccepteerd',
			declined: 'De charter is afgewezen'
		},
		attachments: {
			types: {
				advising: 'Transport informatie',
				charter: 'Charter',
				orders: 'Opdrachtbevestiging',
				'loading-document': 'Laadbewijs',
				'unloading-document': 'Losbewijs',
				'bill-of-lading': 'Cognossement',
				invoice: 'Factuur',
				'container-manifest': 'Manifest',
				other: 'Overig bestand',
				nomination: 'Nominatie',
				'time-sheet': 'Urenstaat',
				'transport-confirmation': 'Transportbevestiging',
				'transport-order': 'Transportopdracht',
				'stowage-plan-stability-calculation': 'Stuwplan / Stabiliteitsberekening',
				'loading-plan': 'Laadplan',
				'discharge-plan': 'Losplan',
				'declaration-of-release': 'Losverklaring',
				'adn-checklist': 'ADN-controlelijst',
				'voyage-preparation': 'Reis voorbereiding',
				'vessel-data': 'Scheepscijfers',
				'letter-of-protest': 'Protestbrief',
				'customs-document': 'Douanedocument',
				'temperature-registration': 'Temperatuurregistratie',
				'empty-vessel-transport-document': 'Vervoersdocument (leegvaart)',
				'building-materials-loading': 'Laadbon bouwmaterialen',
				'building-materials': 'Losbon bouwmaterialen',
				'bill-of-lading-transport': 'Transport cognossement',
				'barging-letter-front': 'Beunbrief (voor)',
				'barging-letter-rear': 'Beunbrief achter',
				'contact-card': 'Contactkaart',
				'hopper-measurement': 'Beunmeting',
				'measurement-certificate': 'Meetbrief',
				'lci-report': 'LCI rapport',
				'chloride-inspection-report': 'Chloride keuringsrapport',
				'purchase-order': 'Inkoop order',
				'product-analysis': 'Product analyse'
			},
			camera: 'Camera',
			upload: 'Upload',
			generate: {
				title: 'Uploaden of genereren',
				upload: 'Upload document',
				generate: 'Genereer document'
			},
			drop: {
				description: 'Drop hier uw bestand om het aan het dossier toe te voegen',
				description_permanent: 'Drop hier uw bestand of klik op de button',
				button: 'upload'
			}
		},
		bid: {
			date_of_bid: 'Datum',
			header: 'Bieden',
			preview_charter: 'Inplannen + contract maken',
			tonnage_extra: '(op een diepte van 3,20 m)'
		}
	},
	locks: {
		number_of_chambers: 'Aantal schutkolken',
		tide_lengths: '(eb: {lowTide} | vloed: {highTide})',
		sill_depth: 'Drempeldiepte',
		location: 'Ligging',
		delays: 'Drukte sluispassage',
		east: 'Oost',
		west: 'West',
		north: 'Noord',
		south: 'Zuid',
		delay_graph_tooltip: '{delay} minuten vertraging',
		graph_vertical_axis_title: 'Vertraging (minuten)'
	},
	car_scaffolding: {
		hasWasteDisposal: 'Huisvuilcontainer',
		hasDrinkingWater: 'Drinkwater'
	},
	bunker_stations: {
		hasBunkerBoat: 'Bunkerboot aanwezig',
		widthPosition: 'Breedte positie'
	},
	berths: {
		maximal_ship_length: 'Maximale lengte schip',
		minimal_ship_length: 'Minimale lengte schip',
		moored_ships_length: 'Afgemeerde schepen lengte',
		condition: 'Conditie',
		note: 'Notitie',
		length: 'Lengte',
		width: 'Breedte',
		current_status: 'Actuele bezetting',
		tooltip_status: '{currentStatus}% vol'
	},
	bridges: {
		number_of_openings: 'Aantal openingen',
		max_height_opened: 'Max. hoogte open',
		max_depth: 'Max. diepte',
		current_status: 'Actuele status'
	},
	water_levels: {
		reference: 'Referentie',
		high_water: 'Hoogwater',
		low_water: 'Laagwater',
		flow: 'Debiet',
		temperature: 'Temperatuur',
		details: 'Overige details',
		latest_measurement: 'Laatste meting',
		based_on_dutch_nap: 'Waterstand t.o.v. NAP (in cm)',
		correction:
			'Bepaal zelf de correctiefactor met deze slider, om de waterstand om te rekenen naar de vaardiepte:',
		no_data_available: 'Er zijn geen metingen voor dit meetpunt bekend.',
		adjustment: 'Pegelstand',
		max_depth: 'Maximale diepgang'
	},
	ports: {
		website: 'Website',
		harbourMasterPhoneNumber: 'Havenmeester',
		harbourDuesButton: 'Aanmelden havengelden',
		facilities: 'Faciliteiten'
	},
	search: {
		placeholder: 'Zoek faciliteit'
	},
	'track-and-trace': {
		client_email: 'Klant emailadres',
		skipper_email: 'Schipper emailadres',
		start_terminal_email: 'Laadlocatie emailadres',
		end_terminal_email: 'Loslocatie emailadres',
		add_transport_infos: 'Transportinformatie toevoegen',
		transport_infos:
			'Transportinformatie: {newline}' +
			'Uw referentie: {clientOrderReference}{newline}' +
			'Scheepsnaam: {shipName} ({dimensions}){newline}' +
			'Schipper: {skipperName}{newline}' +
			'Tel: {phoneNumber}{newline}' +
			'{newline}' +
			'Laadlocatie: {loadingPlace} - {startTerminal}{newline}' +
			'Laden: {loadingTerm}{newline}' +
			'{newline}' +
			'Loslocatie: {unloadingPlace} - {endTerminal}{newline}' +
			'Lossen: {unloadingTerm}{newline}' +
			'{newline}' +
			'Product: {productName}{newline}' +
			'Hoeveelheid: {quantity}',
		header: 'Deel Track & Trace',
		description:
			'Hier onder kunt u de e-mailadressen invoeren naar wie u de track & trace code wilt sturen. U typt het e-mailadres in en drukt vervolgens op de enter toets om hem toe te voegen. ',
		email_addresses: 'E-mailadressen',
		share: 'Delen',
		success: 'Het delen van je Track & Trace is gelukt.',
		warning: 'Zonder MMSI nummer kun je je Track & Trace code niet delen.',
		invalid_email: '{email} is geen geldig e-mailadres',
		comments: 'Opmerkingen',
		preview_link: 'Klik hier voor een voorbeeld.',
		loading: 'Laden',
		'other-traces': 'Eerdere track&traces'
	},
	validation: {
		custom: {
			password: {
				regex: 'Het ingevulde wachtwoord moet minimaal één letter, één cijfer en één van de volgende karakters bevatten !$#%.'
			},
			mmsiNumber: {
				required: 'Het MMSI Nummer veld is verplicht'
			},
			approve: {
				required: 'Ga akkoord om door te gaan'
			}
		},
		messages: {
			_default: 'Het {0} veld is niet correct ingevuld.',
			required: 'Het {0} veld is verplicht.',
			regex: 'Het ingevulde {0} voldoet niet aan de eisen.',
			email: 'Het opgegeven emailadres is ongeldig.',
			min: 'Vul minstens {1} karakters in.',
			max_value: 'Het {0} veld mag maximaal een waarde hebben van {1}.',
			max: 'Het {0} veld mag maximaal een lengte hebben van {1} karakters.'
		},
		attributes: {
			role: 'rollen',
			email: 'emailadres',
			company: 'Bedrijfsnaam',
			password: 'wachtwoord',
			termsAndConditions: 'algemene voorwaarden',
			privacyStatement: 'privacy verklaring'
		},
		scroller: {
			title: 'De volgende velden zijn niet correct ingevuld, klik op de regel om naar het veld te gaan.'
		}
	},
	forms: {
		company: {
			address: 'Adres *',
			city: 'Plaatsnaam *',
			cocNumber: 'KvK-nummer *',
			companyName: 'Bedrijfsnaam *',
			country: 'Land *',
			email: 'E-mailadres',
			firstName: 'Voornaam *',
			lastName: 'Achternaam *',
			hasAlternativeInvoiceDetails: 'Ik heb een ander factuuradres',
			hasAlternativeMailDetails: 'Ik heb een ander postadres',
			phoneCountryCode: 'Landcode *',
			phoneNumber: 'Telefoonnummer *',
			postalCode: 'Postcode *',
			vatNumber: 'BTW-nummer *'
		},
		client: {
			address: 'Adres',
			city: 'Plaatsnaam *',
			country: 'Land *',
			companyName: 'Bedrijfsnaam *',
			email: 'E-mailadres *',
			firstName: 'Voornaam *',
			lastName: 'Achternaam *',
			phoneCountryCode: 'Landcode *',
			phoneNumber: 'Telefoonnummer *',
			postalCode: 'Postcode',
			vatNumber: 'BTW nummer',
			cocNumber: 'KvK nummer'
		},
		vessel: {
			email: 'Emailadres *',
			euNumber: 'ENI nummer (Europa nummer) *',
			insurance: 'Verzekering *',
			length: 'Lengte (in meter) *',
			width: 'Breedte (in meter) *',
			draught: 'Diepte (in meter) *',
			emptyWeight: 'Leeggewicht (in ton) *',
			name: 'Scheepsnaam *',
			tonnage: 'Tonnage (in ton) *'
		},
		unregistered_vessel: {
			tonnageUncovered: 'Capaciteitstonnage (in ton, onafgedekt)',
			email: 'Emailadres',
			name: 'Scheepsnaam',
			euNumber: 'ENI nummer (Europa nummer)',
			skipperName: 'Schipper',
			tonnage: 'Laadvermogen tonnage (in ton)',
			capacityCubicMeters: 'Laadvermogen kuubs (in m3)',
			capacityCubicMetersUncovered: 'Laadvermogen kuubs (in m3, met beunkleppen open)',
			length: 'Lengte (in meter)',
			width: 'Breedte (in meter)',
			draught: 'Diepgang (in meter)',
			emptyWeight: 'Leeggewicht (in ton)',
			mmsi: 'MMSI nummer (AIS)',
			gmpNumber: 'GMP nummer',
			gmpValidUntil: 'GMP geldig tot'
		}
	},
	messages: {
		overview: {
			header: 'Berichten',
			'no-messages': 'Er zijn nog geen berichten ontvangen.',
			file_upload: 'Document'
		},
		item: {
			'input-placeholder': 'Typ een bericht...',
			userLeft: '{fullName} heeft het gesprek verlaten'
		},
		join: 'Deelnemen',
		empty: 'Selecteer een conversatie om de berichten te lezen.'
	},
	groups: {
		title: 'Jouw groepen',
		favouriteVessels: {
			title: 'Favoriete schepen',
			details:
				'Hier kan je verschillende groepen aanmaken en beheren waaraan je schepen toe kan voegen zodat je transporten specifiek aan deze groep kan aanbieden/tenderen.'
		},
		clientGroups: {
			title: 'Klantgroepen',
			details: 'Je kunt hier verschillende klantgroepen beheren zodat die gebruikt kunnen worden in prijsbeheer.',
			makeNewClientGroup: 'Nieuwe klantgroep maken.',
			newClientGroupName: 'Naam van de nieuwe klantgroep.',
			add: 'Toevoegen',
			edit: 'Wijzigen',
			clientGroupDeleted: 'De klantgroep is verwijderd.',
			clientGroupSavedSuccessfully: 'Je nieuwe klantgroep is aangemaakt.',
			client: 'Klant',
			clients: 'Klanten',
			addClientGroupMember: 'Toevoegen',
			noClientGroupMember: 'Je hebt nog geen klant in de huidige groep. Zoek en voeg er een toe.',
			inviteWithdrawn: 'De klant is uit de groep verwijderd',
			duplicateClientMessage: 'Deze klant is al toegevoegd.'
		},

		favouriteBrokers: {
			title: 'Favoriete bevrachters',
			details:
				'Hier kan je verschillende groepen aanmaken en beheren waar je geregistreerde bevrachters aan toe kan voegen zodat je transporten specifiek aan deze groep kan aanbieden/tenderen. Let op, hier kan je uitsluitend bevrachters zoeken die dit aangezet hebben.'
		},
		fleets: {
			title: 'Eigen vloot',
			details:
				'Hier kan je geregistreerde schepen uitnodigen als deze tot je eigen vaste (huur) vloot behoren. Als de schipper dit verzoek accepteert dan krijg je meer rechten om informatie van deze schepen te zien. Dit is noodzakelijk voor o.a. de container monitor.'
		},
		clients: {
			title: 'Opdrachtgevers',
			clean_slate: {
				headline: 'Lidmaatschap nodig',
				request: 'Wil je opdrachtgevers beheren en voorwaarden vastleggen bij transporten?',
				contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
				contact_details: 'info@4shipping.com of +31 85 40 189 40.'
			},
			details:
				'Hier kan je zelf je opdrachtgevers met hun gegevens beheren, zodat je bijvoorbeeld opdrachtbevestigingen kan maken.'
		},
		vessels: {
			title: 'Schepenlijst',
			details:
				'Hier kan je zelf je (ongeregistreerde) schepen beheren (deze zijn voor niemand zichtbaar, maar uitsluitend voor jou). Zo kan je charters maken en bijvoorbeeld een overzicht downloaden van de scheepsgegevens.'
		},
		brokers: {
			title: 'Bevrachters',
			clean_slate: {
				headline: 'Lidmaatschap nodig',
				request: 'Wil je bevrachters beheren en transporten inplannen via deze bevrachters?',
				contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
				contact_details: 'info@4shipping.com of +31 85 40 189 40.'
			},
			details:
				'Hier kan je zelf de bevrachters/rederijen met hun gegevens beheren, zodat je bijvoorbeeld transporten kan inplannen via die bevrachter/rederij.'
		}
	},
	favourite_groups: {
		favouriteGroups: 'Favoriete groepen',
		vessels: '0 schepen | 1 schip | {count} schepen',
		brokers: '0 bevrachters | 1 bevrachter | {count} bevrachters',
		noGroups: 'Je hebt nog geen favoriete groepen. Klik op de knop om je eerste groep aan te maken.',
		makeNewGroup: 'Maak nieuwe groep',
		newGroupName: 'Naam van de nieuwe groep',
		searchVessel: 'Zoek op basis van scheepsnaam om die toe te voegen aan de nieuwe groep.',
		groupSavedSuccessfully: 'Uw nieuwe groep is aangemaakt',
		groupMemberRemoved: 'Het groepslid is verwijderd uit de groep',
		favouriteGroupMembers: {
			addMember: 'Nieuw schip',
			addBrokerMember: 'Bevrachter toevoegen',
			noGroupMembers: 'Je hebt nog geen leden in deze favoriete groep. Klik op de knop om leden toe te voegen.',
			memberSavedSuccesfully: 'Een nieuwe gebruiker is toegevoegd aan uw groep.'
		},
		add: 'Voeg toe',
		edit: 'Pas aan',
		groupDeleted: 'De groep is verwijderd.'
	},
	fleets: {
		fleets: 'Vloten',
		ships: 'schepen',
		ship: 'schip',
		noFleets: 'Je hebt nog geen eigen vloot. Klik op de knop om je eerste vloot aan te maken.',
		noFleetMember: 'Je maakt nog geen onderdeel uit van een vloot.',
		introductionSkipper: 'Hieronder zie je aan welke vloot je deelneemt of deelgenomen hebt.',
		makeNewFleet: 'Maak nieuwe vloot',
		newFleetName: 'Naam van de nieuwe vloot',
		fleetSavedSuccessfully: 'Uw nieuwe vloot is aangemaakt',
		fleetMembers: {
			addMember: 'Nieuw schip',
			pricePerDay: 'Prijs per dag',
			rentalDaysPerWeek: 'Aantal dagen per week',
			dailyShippingTimeInHours: 'Aantal uren vaartijd per dag',
			validFrom: 'Vanaf',
			validUntil: 'Tot aan',
			dateUnknown: 'Voor onbepaalde tijd',
			noDateSpecified: 'Datums niet bekend',
			noFleetMembers: 'Je hebt nog geen schepen in deze vloot. Klik op de knop om een schip toe te voegen.',
			memberSavedSuccesfully: 'Een nieuw schip is toegevoegd aan je vloot.',
			memberEdittedSuccesfully: 'De start- en/of einddatum aangepast.'
		},
		newInvite: 'Nieuwe uitnodiging',
		newInvitee: 'Uitgenodigd',
		add: 'Voeg toe',
		edit: 'Pas aan',
		fleetDeleted: 'Je vloot is verwijderd',
		inviteWithdrawn: 'Je uitnodiging is ingetrokken.',
		inviteDeclined: 'Je hebt de uitnodiging afgeslagen. Je bent geen onderdeel van de vloot (meer).',
		inviteAccepted: 'Je hebt de uitnodiging geaccepteerd.'
	},
	transports: {
		alertMessage: 'Chloride controlemeting vereist!',
		alertCompleted: 'Chloride controlemeting vereist en uitgevoerd',
		clientDossier: 'Bekijk het klant dossier van de huidige',
		ownDossier: 'Bekijk het eigen dossier van de huidige',
		createOwnDossier: 'Eigen dossier maken',
		createCombinedInvoice: 'Gecombineerde factuur maken',
		combinedInvoiceSuccess: 'De gecombineerde factuur is aangemaakt, deze kunt u vinden op de pagina `Financieel`.',
		preparePurchaseOrder: 'Inkooporder voorbereiden',
		downloadDocument: 'Documenten downloaden',
		clientOrderReference: '(Inkoop) referentie:',
		dossierNumber: 'Dossiernummer:',
		vesselName: 'Scheepsnaam:',
		cargoType: 'Product:',
		clientName: 'Klant:',
		productType: 'Productsoort:',
		createdByUser: 'Gemaakt door:',
		from: 'Van:',
		to: 'Naar:',
		until: 'Tot:',
		unloadingEnd: 'Ik ga lossen',
		actions: {
			shareTrackAndTrace: 'Deel track en trace',
			cargoDetails: 'Vracht details',
			cargoChange: 'Vracht aanpassen',
			cargoRemove: 'Vracht verwijderen',
			cargoCopy: 'Vracht kopieeren',
			shipChange: 'Schip wijzigen'
		},
		transport: 'Transport',
		search: 'Zoeken...',
		dateFilterType: {
			loading: 'Laaddatum',
			unloading: 'Losdatum',
			based_on_terms: 'o.b.v. betaalbaar'
		},
		searchByDossierNumber: 'Zoeken op dossiernummer...',
		searchByVesselName: 'Zoeken op scheepsnaam...',
		searchByCargoType: 'Zoeken op product...',
		searchByClientOrderReference: 'Zoeken op (inkoop) referentie…',
		searchByClientName: 'Zoeken op klant...',
		noTransports: 'U heeft nog geen lopende transport',
		noHistoricTransports: 'U heeft nog geen verlopen transporten',
		showHistory: 'Toon historische transporten',
		table: {
			sections: {
				transport: 'Transport',
				actions: 'Acties'
			},
			headers: {
				dossierNumber: 'Dossiernummer',
				product: 'Product',
				vessel: 'Schip',
				price: 'Prijs',
				orderPrice: 'Klantprijs',
				skipper: 'Schipper',
				loading: 'Laden',
				unloading: 'Lossen',
				client: 'Klant',
				startLocation: 'Laadlocatie',
				destinationLocation: 'Loslocatie',
				status: 'Status',
				alert: 'Waarschuwing',
				actions: 'Acties',
				documents: 'Documenten'
			},
			unloadingBillAttached: 'Losbon in dossier',
			externalInvoiceAttached: 'Externe factuur in dossier',
			trackTraceSent: 'Track&Trace link verstuurd'
		},
		eta: 'ETA',
		etb: 'ETB',
		etd: 'ETD',
		etaUpdated: 'Een nieuwe ETA is succesvol geplaatst',
		etdUpdated: 'Een nieuwe ETD is succesvol geplaatst',
		editEta: 'ETA aanpassen',
		editEtb: 'ETB aanpassen',
		editEtd: 'ETD aanpassen',
		chatUnavailable: 'Chat is alleen mogelijk met geregistreerde schippers.',
		arrived: 'Aangekomen',
		arrivalUpdated: 'Een nieuwe aankomsttijd is geregistreerd',
		editArrival: 'Aankomsttijd wijzigen',
		loading: 'Laden op',
		unloading: 'Lossen op',
		report_on_arrival: 'Melden bij aankomst',
		loadingLocation: 'laadlocatie',
		unloadingLocation: 'loslocatie',
		status: {
			finished: 'Afgeleverd',
			operational_closed: 'Losbon toegevoegd',
			checked: 'Gecontroleerd',
			closed: 'Afgesloten'
		},
		locationStatus: {
			arrived: 'Gearriveerd',
			finished: 'Overslag afgerond',
			headingTo: 'Varen',
			loading: 'Laden',
			unloading: 'Lossen',
			transshipping: 'Overslag'
		},
		headingToStart: 'Ik ga varen',
		notifyArrival: 'Aankomst melden',
		loadingStart: 'Ik ga laden',
		headingToEnd: 'Ik ga weer varen',
		unloadingStart: 'Ik ga lossen',
		notifyFull: 'Ik ben vol',
		notifyEmpty: 'Ik ben leeg',
		finished: 'Vracht is afgeleverd',
		endOfJourney: 'Einde transport',
		checkTransport: 'Transport gecontroleerd',
		reopenTransport: 'Heropen transport',
		updater: {
			etaTitle: 'Geef je ETA op (verwachte aankomsttijdstip)',
			eta: 'Verwacht per',
			etbTitle: 'Geef je ETB op (verwachte aanvangtijdstip)',
			etb: 'Aanvang per',
			etbUnknown: 'Nog niets bekend (geen vooruitzichten)',
			etbUnknownShort: 'Nog niets bekend',
			etbComment: 'Opmerking',
			etdTitle: 'Geef je ETD op (verwachte vertrektijdstip)',
			etd: 'Vertrek per',
			headingToTitle: 'Registreer je tijdstip start varen',
			headingToAt: 'Start varen',
			arrivalTitle: 'Registreer je aankomsttijdstip',
			arrivedAt: 'Aangekomen op',
			transshippingTitle: 'Registreer je tijdstip start overslag',
			transshippingAt: 'Start overslag op',
			finishedTitle: 'Registreer je tijdstip einde overslag',
			finishedAt: 'Einde overslag op',
			loadedQuantity_req: 'Ingeladen hoeveelheid *',
			loadedQuantity: 'Ingeladen hoeveelheid',
			dischargedQuantity_req: 'Uitgeloste hoeveelheid *',
			dischargedQuantity: 'Uitgeloste hoeveelheid',
			draught: 'Diepgang',
			draughtUnit: 'meter',
			save: 'Opslaan',
			editLogTitle: 'Logboek wijzigen voor {location}'
		},
		quickStatusUpdater: {
			openFormButton: 'Voer laad/los gegevens in',
			title: 'Laad/los gegevens invoeren'
		},
		vesselPosition: {
			title: 'Geef een handmatige positiemelding door',
			reportPosition: 'Positie melden',
			searchLocation: 'Zoek locatie',
			mandatoryField: 'Dit is een verplicht veld',
			recordDatetime: 'Kies tijd',
			save: 'Opslaan',
			reportSuccess: 'Positie gemeld'
		},
		addFile: 'Voeg bestand toe',
		generateDocument: 'Genereer document',
		sharedWithSubContractor: 'Gedeeld met onderaannemer',
		fileTags: {
			'container-manifest': 'Container Manifest',
			charter: 'Charter',
			'loading-document': 'Laadbewijs',
			'unloading-document': 'Losbewijs',
			'bill-of-lading': 'Cognossement',
			invoice: 'Factuur',
			other: 'Overig'
		},
		overview: 'Transport overzicht',
		documents: 'Documenten',
		noBillOfLadings: 'Dit transport heeft momenteel geen laadbewijs of losbewijs',
		billOfLadings: 'Laadbewijs en losbewijs',
		loadingBill: 'Laadbewijs',
		unloadingBill: 'Losbewijs',
		document: {
			share: {
				title: 'Document delen',
				cancel: 'Annuleren',
				close: 'Sluiten',
				successShared: 'Het document is gedeeld met de onderaannemer.',
				successUnshared: 'Het document is nu verborgen voor de onderaannemer.',
				noFileSelected: 'Geen bestand geselecteerd.',
				unregisteredVessel: {
					shared: 'Dit document is al gedeeld met de onderaannemer via email.',
					notShared: 'Weet je zeker dat je dit document wil delen met de onderaannemer via email?',
					confirmSharing: 'Verstuur'
				},
				vessel: {
					shared: 'Wil je het delen met de onderaannemer intrekken?',
					notShared: 'Weet je zeker dat je dit document wil delen met de onderaannemer?',
					confirmSharing: 'Delen',
					confirmUnsharing: 'Intrekken'
				},
				transport: {
					own: 'Kopieer naar klant dossier',
					external: 'Kopieer naar eigen dossier',
					success: 'Bestand is gekopieerd',
					error: 'Bestand kopiëren is mislukt',
					shared: 'Dit document is al gedeeld met de opdrachtgever',
					notShared: 'Weet je zeker dat je dit document wil delen met de opdrachtgever?'
				}
			},
			shared: {
				withSubContractor: 'Met onderaannemer',
				notShared: 'Niet gedeeld'
			},
			deleteSuccess: 'Het document is succesvol verwijderd.'
		},
		manifestDetails: {
			manifestDetails: 'Manifest / Laad / Loslijst',
			equipmentNumber: 'Container nummer',
			statuses: 'Statussen',
			weight: 'Gewicht',
			measurements: 'Afmetingen',
			status: {
				blocked: 'Geblokkeerd',
				customsDocument: 'Douanedocument',
				cargoOpening: 'Beschikbaar',
				bookingApproved: 'Goedgekeurd',
				removed: 'Verwijderd uit PortBase',
				notConfirmed: 'Niet bevestigd'
			},
			confirmCancel: {
				title: 'Bevestig annulering',
				body: 'Weet u zeker dat u deze handling in PortBase wil annuleren?',
				button: 'Bevestig'
			},
			switchCompany: {
				title: 'Wijzig rederij',
				button: 'Opslaan',
				company: 'Rederij'
			}
		},
		shipDetails: {
			shipDetails: 'Scheepsdetails',
			dimensions: 'Afmeting',
			tonnage: 'Tonnage',
			draught: 'Diepgang',
			showContactCard: 'Toon volledige details',
			editVessel: 'Wijzig schip'
		},
		contactDetails: {
			contactDetails: 'Contactgegevens',
			companyName: 'Bedrijfsnaam',
			name: 'Naam',
			phoneNo: 'Telefoonnummer',
			email: 'E-mail'
		},
		logDetails: {
			editLogTitle: 'Bewerk logboek voor {location}',
			save: 'Opslaan',
			logDetails: 'Logboekgegevens',
			startPlace: 'Herkomst',
			destinationPlace: 'Bestemming',
			eta: 'ETA',
			etb: 'ETB',
			etd: 'ETD',
			headingToDatetime: 'Tijdstip start varen',
			arrivalDatetime: 'Aankomsttijdstip',
			transshippingDatetime: 'Tijdstip start overslag',
			finishedDatetime: 'Tijdstip overslag afgerond',
			loadedQuantity: 'Ingeladen hoeveelheid',
			dischargedQuantity: 'Uitgeloste hoeveelheid',
			draught: 'Diepgang',
			draughtUnit: 'meter'
		},
		financialDetails: {
			financialDetails: 'Financiële gegevens',
			status: 'Status',
			client: 'Opdrachtgever',
			transporter: 'Transporteur',
			createInvoice: 'Maak nieuwe factuur',
			revenue: 'Omzet',
			costs: 'Kosten',
			sales: 'Opbrengst',
			margin: 'Marge',
			saveFinancials: 'Opslaan',
			products: 'Producten',
			invoiceNumber: 'Factuurnummer',
			invoiceDate: 'Factuurdatum',
			external: 'Externe factuur',
			otherProducts: 'en 1 ander product | en {count} andere producten',
			actions: {
				send: 'verzenden',
				edit: 'wijzig',
				delete: 'verwijder'
			},
			invoices: {
				title: 'Facturen',
				sales: 'Opbrengst',
				purchase: 'Kosten'
			},
			invoiceLines: {
				title: 'Financiële registraties',
				status: {
					initial: 'concept',
					filled: 'gevuld',
					checked: 'gecontroleerd',
					invoiced: 'factuur aangemaakt'
				},
				add: 'Financiële regel toevoegen',
				edit: 'Financiële regel wijzigingen',
				delete: 'Financiële regel verwijderen',
				edited_successfully: 'Financiële registratie is succesvol bijgewerkt.',
				deleted_successfully: 'Financiële registratie is succesvol verwijderd.',
				invoiced_notification:
					'Financiële registratie is al gefactureerd, de factuur wordt niet automatisch aangepast.',
				error_while_updating:
					'Er is een fout opgetreden tijdens het bijwerken van de financiële registratie. Probeer het later opnieuw.',
				error_while_deleting:
					'Er is een fout opgetreden tijdens het verwijderen van het financiële registratie. Probeer het later opnieuw.',
				save: 'opslaan',
				cancel: 'annuleren',
				create: 'factureren',
				disabled: 'Vul eerst laad/losgegevens in',
				priceDependentOn: 'over {amount}',
				incomingInvoice: 'Inkomende factuur registreren',
				relationOther: 'Niet gekoppeld aan relatie',
				nya: 'N.n.b.'
			}
		},
		gasOilUsages: {
			created_succesfully: 'Uw gasolie verbruik is geregistreerd',
			gasOilUsages: 'Gasolie verbruik',
			showWeekNumbers: 'Toon weeknummers',
			gasOil: 'Gasolie',
			edit: 'Wijzig',
			add: 'Aanmaken',
			perWeek: 'Per week',
			perPeriod: 'Per periode',
			weekNumber: 'Week nummer',
			gasOilUsageDeleted: 'Gasolie registratie verwijderd',
			noGasOilUsages: 'U heeft nog geen geregistreerd verbruik van gasolie',
			gasOilLevelStart: 'Begin bunkerstand in m3',
			gasOilLevelEnd: 'Eind bunkerstand in m3',
			gasOilBunkered: 'Gasolie gebunkerd in m3',
			usage: 'Verbruik',
			usageInCubic: 'Verbruik in m3',
			startDate: 'Start datum',
			endDate: 'Eind datum',
			permissionsToIncrementSpeed: 'Aantal toestemmingen harder varen',
			calculationWarning: 'Uw eindstand is hoger dan beginstand. Verhoog bunker waarde.',
			calculationWarningUnrealistic: 'Te hoog verbruik ({value} m3) voor opgegeven periode.',
			bunkerReceipt: 'Bunkerbon',
			addBunkerReceipt: {
				uploadReceipt: 'Upload bon',
				addBunkerReceipt: 'Voeg bunkerbon toe',
				add: 'Voeg toe',
				cancel: 'Annuleren',
				pickFile: 'Kies bestand',
				makePhoto: 'Neem foto'
			}
		},
		vesselLogs: {
			logBook: 'Logboek',
			vesselLogs: 'Schip logboek',
			vesselLogDeleted: 'Logboek item verwijderd',
			terminal: 'Terminal',
			berth: 'Ligplaats',
			bunker_station: 'Bunkerstation',
			lock: 'Sluis',
			showVesselLogs: 'Toon logboek',
			showGasOilUsage: 'Toon gasolie verbruik',
			noVesselLogs: 'U heeft nog geen geregistreerde logboek activiteiten',
			created_succesfully: 'Uw schip log is opgeslagen!',
			vesselLog: 'Logboek item',
			mmsi: 'mmsi',
			sailed_during_action: 'Gevaren tijdens deze actie',
			arrival_time: 'Aankomst dag/tijd:',
			departure_time: 'Vertrek dag/tijd:',
			arrival_location: 'Aankomst locatie:',
			departure_location: 'Vertrek locatie:',
			add: 'Aanmaken',
			edit: 'Wijzig',
			start_time_trans_shipment: 'Begintijd laden/lossen',
			end_time_trans_shipment: 'Eindtijd laden/lossen',
			loadedContainerQuantity: 'Ingeladen hoeveelheid containers',
			dischargedContainerQuantity: 'Geloste hoeveelheid containers',
			rearrangedContainerQuantity: 'Verzette hoeveelheid containers',
			add_additional_info: 'Ik wil extra informatie doorgeven',
			add_comment: 'Ik wil een opmerking toevoegen',
			comments: 'Opmerkingen',
			add_comment_labels: 'Opmerking categorie',
			addVesselLog: 'Voeg toe',
			searchFacility: 'Zoek faciliteit',
			unknownLocation: 'Locatie onbekend',
			gasOilPricePerCubic: 'Prijs per m3',
			gasOilBunkeredQuantity: 'Gebunkerde hoeveelheid in m3',
			selectVessel: 'Selecteer een schip'
		},
		requiredDocuments: {
			title: 'Verplichte documenten'
		}
	},
	preferences: {
		notifications: 'Meldingen',
		generalPreferences: 'Voorkeuren',
		charterInfo: 'Charter informatie',
		invoiceInfo: 'Facturen',
		shipDetails: 'Scheepsdetails',
		shipDocuments: 'Scheepsdocumenten',
		savedSuccesfully: 'Uw wijzigingen zijn verwerkt.',
		'keep-up-to-date': 'Hou je voorkeuren actueel!',
		vesselAttributes: {
			attributelabels: 'moeten hier komen'
		},
		invoice: {
			title: 'Factuur instellingen',
			documentFooter: 'Factuur voettekst'
		}
	},
	profile: {
		'keep-up-to-date': 'Hou je gegevens actueel!',
		user: 'Gebruiker',
		connections: 'Koppelingen',
		userManagement: 'Gebruikersbeheer',
		company: 'Bedrijf',
		vessel: 'Schip',
		edit: 'Sla wijzigingen op',
		edittedSuccessfully: 'Uw wijzigingen zijn verwerkt.',
		'user-details': {
			firstName: 'Kies voornaam',
			lastName: 'Kies achternaam',
			password: 'Kies nieuw wachtwoord',
			email: 'Uw e-mailadres',
			language: 'Taal',
			passwordStrength: {
				weak: 'Zwak',
				medium: 'Matig',
				strong: 'Sterk!'
			},
			passwordHints: {
				useNumbersAndSpecialCharacters: 'Gebruik cijfers en speciale tekens',
				useNumbers: 'Gebruik cijfers',
				useSpecialCharacters: 'Gebruik speciale tekens'
			}
		},
		'company-details': {
			disclaimer: 'Disclaimer',
			companyName: 'Bedrijfsnaam',
			reference: 'Referentie',
			cocNumber: 'KvK-nummer',
			vatNumber: 'BTW-nummer',
			iban: 'IBAN nummer',
			streetName: 'Adres',
			postalCode: 'Postcode',
			place: 'Plaats',
			country: 'Land',
			website: 'Website',
			phoneCountryCode: 'Landcode',
			phoneNumber: 'Telefoonnummer',
			hasSecondaryPhone: 'Ik wil een tweede telefoonnummer doorgeven',
			hasTertiaryPhone: 'Ik wil een derde telefoonnummer doorgeven',
			hasAlternativeInvoiceDetails: 'Ik heb een ander factuuradres',
			hasAlternativeMailDetails: 'Ik heb een ander postadres',
			invoiceEmail: 'Factuuradres e-mailadres',
			invoiceStreetName: 'Factuuradres straat',
			invoicePostalCode: 'Factuuradres postcode',
			invoicePlace: 'Factuuradres plaats',
			invoiceCountry: 'Factuuradres land',
			mailStreetName: 'Postadres straat',
			mailPostalCode: 'Postadres postcode',
			mailPlace: 'Postadres plaats',
			mailCountry: 'Postadres land'
		},
		'vessel-details': {
			euNumber: 'ENI nummer (Europa nummer)',
			insurance: 'Verzekering',
			length: 'Lengte (in meter)',
			name: 'Scheepsnaam',
			tonnage: 'Tonnage (in ton)',
			phoneCountryCode: 'Landcode',
			phoneNumber: 'Telefoonnummer'
		},
		'connection-details': {
			intro: 'U kunt op deze pagina uw koppelingen beheren met uw financiële pakket.',
			exact: {
				title: 'Exact-Online',
				details: 'Koppel uw account zodat facturen worden aangemaakt in Exact-Online.'
			},
			internal: {
				title: '4Shipping',
				details: 'Maak facturen in 4Shipping en beheer deze op de facturatie pagina.',
				success: 'De facturatie module is succesvol geactiveerd.',
				saved: 'De configuratie is succesvol opgeslagen.'
			},
			status: 'Status',
			activated: 'Geactiveerd',
			connectedUntil: 'Koppeling actief tot',
			expiredOn: 'Koppeling verlopen op',
			notConnected: 'Niet verbonden',
			notActivated: 'Niet geactiveerd',
			connect: 'Verbind',
			activate: 'Activeer',
			forwardEmail: 'Doorstuur adres',
			clean_slate: {
				headline: 'Lidmaatschap nodig',
				request: 'Wil je facturen aanmaken en versturen via 4Shipping?',
				contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
				contact_details: 'info@4shipping.com of +31 85 40 189 40.'
			}
		},
		'connection-callback': {
			title: 'Koppeling succesvol',
			message: 'U kunt dit venster sluiten en verder werken in uw vorige tabblad.'
		}
	},
	customLocation: {
		add: 'Toevoegen',
		update: 'bijwerken',
		updateLocation: 'terminalgegevens bijwerken',
		harbour: 'Haven',
		waterway: 'Rivier / kanaal',
		companyName: 'Bedrijfsnaam',
		city: 'Stad',
		country: 'Land',
		geoRequired: 'Selecteer een plaats op de kaart',
		harbourName: 'Havennaam',
		harbourNumber: 'Havennummer',
		waterwayName: 'Rivier/kanaal',
		waterwayKmNumber: 'Kilometernummer',
		phoneNumber: 'Telefoonnummer',
		submit: 'Voeg toe',
		addLocation: 'Voeg zelf een locatie toe',
		created_succesfully: 'Uw custom locatie is aangemaakt!'
	},
	terminal: {
		harbour: 'Haven',
		waterway: 'Rivier / kanaal',
		companyName: 'Bedrijfsnaam',
		city: 'Stad',
		country: 'Land',
		geoRequired: 'Selecteer een plaats op de kaart',
		harbourName: 'Havennaam',
		harbourNumber: 'Havennummer',
		waterwayName: 'Rivier/kanaal',
		waterwayKmNumber: 'Kilometernummer',
		locationMap: 'Kies hier de locatie op de kaart',
		phoneNumber: 'Telefoonnummer',
		emial: 'Email',
		add: 'Voeg toe',
		update: 'Pas aan',
		submit: 'Toevoegen',
		user_managed: 'De gegevens van deze terminal worden beheerd door uw bedrijf.',
		addLocation: 'Voeg zelf een terminal toe',
		updateLocation: 'Wijzig de terminal gegevens',
		created_succesfully: 'Uw terminal is aangemaakt!',
		comments: 'Opmerkingen'
	},
	vessel: {
		listItem: {
			sub: 'ENI: {eni} (L:{length} x B:{width})'
		}
	},
	unregisteredVessel: {
		create: 'Maak aan',
		save: 'Opslaan',
		success: 'Het schip is aangemaakt',
		success_saved: 'Het schip is aangepast',
		listItem: {
			sub: 'ENI: {eni} (L:{length} x B:{width})'
		},
		search: 'Zoek op naam van schip of schipper...'
	},
	confirmAction: {
		delete: {
			actionTranslation: 'Verwijderen',
			actionContexts: {
				registration: {
					description:
						'Weet je zeker dat je de geselecteerde financiële registratie wilt verwijderen? Wanneer de financiële registratie al is gefactureerd blijft de factuurregel bestaan.',
					buttonText: 'Verwijder'
				},
				cargo: {
					description:
						'Weet je zeker dat je de geselecteerde vracht (en indien van toepassing het bijbehorende transport) wil verwijderen?',
					buttonText: 'Verwijder'
				},
				cargoProject: {
					description: 'Weet je zeker dat je het geselecteerde project wil verwijderen?',
					buttonText: 'Verwijder'
				},
				bid: {
					description: 'Weet je zeker dat je het geselecteerde bod wil verwijderen?',
					buttonText: 'Verwijder'
				},
				favouriteGroup: {
					description: 'Weet je zeker dat je de geselecteerde favoriete groep wil verwijderen?',
					buttonText: 'Verwijder'
				},
				favouriteGroupMember: {
					description:
						'Weet je zeker dat je de geselecteerde schipper uit je favoriete groep wil verwijderen?',
					buttonText: 'Verwijder'
				},
				fleetShipper: {
					description: 'Weet je zeker dat je de geselecteerde vloot wil verwijderen?',
					buttonText: 'Verwijder'
				},
				fleetSkipper: {
					reject: {
						description:
							'Weet je zeker dat je je niet bij deze vloot wil aansluiten, en de uitnodiging afslaat?',
						buttonText: 'Sla uitnodiging af'
					},
					leave: {
						description: 'Weet je zeker dat je de geselecteerde vloot wil verlaten?',
						buttonText: 'Verlaat vloot'
					}
				},
				fleetMember: {
					description: 'Weet je zeker dat je de geselecteerde schipper uit je vloot wil verwijderen?',
					buttonText: 'Verwijder'
				},
				clientGroupMember: {
					description: 'Weet je zeker dat je de geselecteerde klant uit je groep wil verwijderen?',
					buttonText: 'Verwijder'
				},
				groupMember: {
					description: 'Weet je zeker dat je het geselecteerde groepslid uit je groep wil verwijderen?',
					buttonText: 'Verwijder'
				},
				groupRemove: {
					description: 'Weet je zeker dat je de geselecteerde groep wil verwijderen?',
					buttonText: 'Verwijder'
				},
				vesselLog: {
					description: 'Weet je zeker dat je het geselecteerde schiplog wil verwijderen?',
					buttonText: 'Verwijder'
				},
				gasOilUsage: {
					description: 'Weet je zeker dat je de geselecteerde gasolie registratie wil verwijderen?',
					buttonText: 'Verwijder'
				},
				unregisteredVesselRemove: {
					description: 'Weet je zeker dat je het geselecteerde schip wil verwijderen?',
					buttonText: 'Verwijder'
				},
				clientRemove: {
					description: 'Weet je zeker dat je de geselecteerde klant wil verwijderen?',
					buttonText: 'Verwijder'
				},
				brokerRemove: {
					description: 'Weet je zeker dat je de geselecteerde bevrachter wil verwijderen?',
					buttonText: 'Verwijder'
				},
				invoiceRemove: {
					description: 'Weet je zeker dat je de geselecteerde factuur wil verwijderen?',
					buttonText: 'Verwijder'
				},
				invoiceCredit: {
					description: 'Weet je zeker dat je de geselecteerde factuur wil crediteren?',
					buttonText: 'Crediteer'
				},
				terminalRemove: {
					description: 'Weet je zeker dat je de terminal wilt verwijderen?',
					buttonText: 'Verwijder'
				},
				emptyVesselReport: {
					description: 'Weet je zeker dat je de leegmelding wilt verwijderen?',
					buttonText: 'Verwijder'
				},
				document: {
					description: 'Weet je zeker dat je het document wilt verwijderen?',
					buttonText: 'Verwijder'
				}
			}
		},
		show: {
			actionTranslation: 'Toon details',
			actionContexts: {
				vessel: {
					shipDetails: 'Schip Details',
					historicTrack: 'Historische track',
					description: 'Druk op X om de details te sluiten',
					buttonText: 'Oké'
				}
			}
		},
		cancel: 'Annuleren'
	},
	dashboard: {
		pleaseSelect: 'Kies een dashboard',
		insights: 'Inzichten',
		vesselLogs: 'Logboek',
		selectFilter: 'Selecteer je filter',
		filterInsights: 'Filter inzichten',
		fleet: 'Vloot',
		clean_slate: {
			headline: 'Bekijk je inzichten!',
			request:
				'Wil je ook realtime de footprint, gasolieverbruik, kostprijs en prestaties van je binnenvaart transport monitoren?',
			contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
			contact_details: 'Info@4shipping.com of +31 85 40 189 40.'
		},
		inventoryManagement: 'Voorraadbeheer',
		portManagement: {
			portReportMessage: 'Beheer automatische rapporten',
			portManagement: '',
			portInsights: 'Haveninzichten',
			selectFilter: 'Selecteer je filter',
			selectAll: 'Alles selecteren',
			shipType_placeholder: 'CEMT-klasse',
			activityType_placeholder: 'Activiteit',
			goodsType_placeholder: 'Goederensoort',
			port_placeholder: 'Haven',
			vessels: 'Schepen',
			vesselDetails: {
				name: 'Naam',
				speed: 'Snelheid',
				eni: 'ENI',
				length: 'Lengte',
				width: 'Breedte',
				typeGeneric: 'Scheepstype - Generiek',
				typeDetailed: 'Scheepstype - Gedetaileerd'
			},
			filters: {
				loading: 'Laden',
				discharge: 'Lossen'
			},
			clean_slate: {
				headline: 'Bekijk je inzichten!',
				request:
					'Wil je ook realtime de Co2 uitstoot, sluisprestatie, ligplaatsbezetting, scheepsbezoeken en overslagvolume van je havengebied(en) monitoren?',
				contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
				contact_details: 'Info@4shipping.com of +31 85 40 189 40.'
			}
		},
		transport: {
			insights: 'Transportinzichten',
			from: 'Van',
			until: 'Tot',
			noData: 'Geen data beschikbaar',
			clean_slate: {
				headline: 'Lidmaatschap nodig',
				request: 'Wil je inzichten in financiële resultaten via 4Shipping?',
				contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
				contact_details: 'info@4shipping.com of +31 85 40 189 40.'
			}
		}
	},
	insights: {
		searchTerminal: 'Zoek terminal',
		week: 'Week',
		month: 'Maand',
		year: 'Jaar',
		all: 'Allemaal',
		altogether: 'Gezamelijk',
		searchOriginTerminal: 'Zoek vertrekpunt terminal/overslagbedrijf',
		searchDestinationTerminal: 'Zoek bestemming terminal/overslagbedrijf',
		co2Emissions: {
			co2Emissions: 'CO2 uitstoot',
			total: 'Totaal',
			perUnit: 'Per unit',
			graph_vertical_axis_title: {
				total: 'CO2 uitstoot totaal (ton)',
				perUnit: 'CO2 uitstoot per unit (ton)'
			},
			graph_tooltip: {
				total: '{value} ton CO2 uitstoot',
				perUnit: '{value} ton CO2 uitstoot'
			}
		},
		transportPrices: {
			transportPrices: 'Transportprijs',
			total: 'Totaal',
			perUnit: 'Per unit',
			perTon: 'Per ton',
			graph_vertical_axis_title: {
				total: 'Transportprijs (x1000)',
				perUnit: 'Transportprijs per unit',
				perTon: 'Transportprijs per ton'
			},
			graph_tooltip: {
				total: '{value} duizend euro',
				perUnit: '{value} euro',
				perTon: '{value} euro'
			}
		},
		voyageDeviations: {
			voyageDeviations: 'Afwijkingen varen',
			total: 'Totaal',
			perUnit: 'Per unit',
			graph_vertical_axis_title: 'Afwijkingen in uur',
			graph_tooltip: {
				total: '{value} uur afgeweken',
				perUnit: '{value} uur afgeweken'
			}
		},
		terminalDeviations: {
			terminalDeviations: 'Afwijkingen terminals',
			arrivalTimeDeviations: 'Aankomst',
			handlingTimes: 'Handelingen',
			departureTimeDeviations: 'Vertrek',
			graph_vertical_axis_title: {
				arrivalTimeDeviations: 'Afwijkingen aankomst in uur',
				handlingTimes: 'Handelingstijd in uur',
				departureTimeDeviations: 'Afwijkingen vertrek in uur'
			},
			graph_tooltip: {
				arrivalTimeDeviations: '{value} uur afgeweken van aankomsttijd',
				departureTimeDeviations: '{value} uur afgeweken van vertrektijd',
				arrivalTimeDeviationsPositive: '{value} uur eerder dan gepland',
				arrivalTimeDeviationsNegative: '{value} uur later dan gepland',
				handlingTimes: '{value} uur handelingstijd',
				departureTimeDeviationsPositive: '{value} uur eerder dan gepland',
				departureTimeDeviationsNegative: '{value} uur later dan gepland'
			}
		},
		gasOil: {
			gasOil: 'Gasolie verbruik & kosten',
			usageInCubicMeter: 'm3',
			usagePriceInEuro: '€',
			usagePricePerCubicMeter: '€/m3',
			graph_vertical_axis_title: {
				usageInCubicMeter: 'Verbruik in m3',
				usagePriceInEuro: 'Kosten in euro',
				usagePricePerCubicMeter: 'Gemiddelde prijs per m3'
			},
			graph_tooltip: {
				usageInCubicMeter: '{value} m3 gasolie verbruik',
				usagePriceInEuro: '{value} euro aan gasolie kosten',
				usagePricePerCubicMeter: '{value} euro gemiddelde kosten per m3'
			}
		},
		shippedCargoes: {
			shippedCargoes: 'Vervoerde vracht',
			shippedContainerUnits: 'Containers',
			shippedTeu: 'Teu',
			shippedTonnage: 'Tonnage',
			graph_vertical_axis_title: {
				shippedTonnage: 'Vervoerd tonnage',
				shippedTeu: 'Vervoerde TEU',
				shippedContainerUnits: 'Vervoerde containers'
			},
			graph_tooltip: {
				shippedTonnage: '{value} ton vervoerde vracht',
				shippedTeu: '{value} teu vervoerde vracht',
				shippedContainerUnits: '{value} vervoerde containers',
				combined: '{emptyValue} lege en {fullValue} volle'
			}
		},
		transShipments: {
			transShipments: 'Overslag volume',
			bulkTonnage: 'Bulk tonnage',
			containerQuantity: 'Containers TEU',
			graph_vertical_axis_title: {
				bulkTonnage: 'Bulk tonnage (ton)',
				containerQuantity: 'Container (TEU)'
			},
			graph_tooltip: {
				bulkTonnage: '{value} ton bulk',
				containerQuantity: '{value} containers'
			}
		},
		vesselVisitations: {
			vesselVisitations: 'Scheepsbezoeken',
			loadingOrDischargeVisits: 'Laden/lossen',
			overnightStayVisits: 'Overnachtingen',
			graph_vertical_axis_title: {
				loadingOrDischargeVisits: 'Laad- of losbezoeken',
				overnightStayVisits: 'Overnachtingen'
			},
			graph_tooltip: {
				loadingOrDischargeVisits: '{value} bezoeken voor laden of lossen',
				overnightStayVisits: '{value} bezoeken voor overnachtingen'
			}
		},
		berthOccupations: {
			berthOccupations: 'Ligplaats bezetting (in %)',
			mediumOccupationPercentages: 'Bezetting gemiddeld',
			highOccupationPercentages: 'Bezetting maximaal',
			graph_vertical_axis_title: {
				mediumOccupationPercentages: '% van totale tijd',
				highOccupationPercentages: '% van totale tijd'
			},
			graph_tooltip: {
				mediumOccupationPercentages: '{value}% van totale tijd',
				highOccupationPercentages: '{value}% van totale tijd'
			}
		},
		lockPerformances: {
			lockPerformances: 'Sluisprestatie (wachttijd in uur)',
			averageDelayTimeInHours: 'Gemiddelde wachttijd',
			maximumDelayTimeInHours: 'Maximale wachttijd',
			graph_vertical_axis_title: {
				averageDelayTimeInHours: 'Gemiddelde wachttijd (uur)',
				maximumDelayTimeInHours: 'Maximale wachttijd (uur)'
			},
			graph_tooltip: {
				averageDelayTimeInHours: '{value} uur afgeweken',
				maximumDelayTimeInHours: '{value} uur afgeweken'
			}
		},
		portCo2Emissions: {
			portCo2Emissions: 'CO2 uitstoot',
			emissionsInTonnage: 'Ton CO2 uitstoot',
			emissionSavingsInTonnage: 'Ton CO2 bespaard',
			graph_vertical_axis_title: {
				emissionsInTonnage: 'CO2 uitstoot totaal (ton)',
				emissionSavingsInTonnage: 'CO2 bespaard (ton)'
			},
			graph_tooltip: {
				emissionsInTonnage: '{value} ton CO2 uitstoot',
				emissionSavingsInTonnage: '{value} ton CO2 bespaard'
			}
		}
	},
	transportInsights: {
		useNewTransportFinancials: 'Nieuwe financiële registraties',
		financialRegistrations: 'Financiële registratie',
		invoiced: 'Gefactureerd',
		groupBy: {
			clients: 'Per klant',
			vessels: 'Per schip',
			dossiers: 'Per dossier'
		},
		filterDialog: {
			all: 'Allemaal',
			selectAll: 'Alles selecteren',
			clients: 'Kies een klant',
			vessels: 'Kies een schip',
			colleagues: 'Kies een collega',
			productType: 'Productcategorie/certificering',
			productTypeLabel: 'Productcategorie/certificering',
			productTypePlaceholder: 'Kies een certificering',
			invoiceProduct: 'Product/dienst',
			cargoProject: 'Project',
			productCategory: 'Product type'
		},
		tableHeadings: {
			transportPrices: 'Financieel',
			shippedCargoes: 'Vervoerde vracht'
		},
		headers: {
			client: 'Klant',
			vessel: 'Schip'
		},
		transportPrices: {
			revenue: 'Omzet',
			margin: 'Marge',
			costs: 'Kosten'
		},
		shippedCargoes: {
			tonnage: 'Ton',
			cubicMeters: 'Kuub'
		},
		dossierInsights: {
			headers: {
				dossierNumber: 'Dossiernummer',
				date: 'Datum',
				client: 'Opdrachtgever',
				vessel: 'Schip (schipper)',
				product: 'Lading',
				quantity: 'Hoeveelheid',
				loadingPlace: 'Laadplaats',
				unloadingPlace: 'Losplaats',
				prognosis: 'Verwachte marge',
				registered: 'Geregistreerde marge',
				invoicedRevenue: 'Gefactureerde inkomsten',
				invoicedCosts: 'Gefactureerde kosten',
				invoiced: 'Gefactureerde marge',
				salesRevenuePrognosis: 'Verwachte omzet',
				salesRevenueRegistered: 'Geregistreerde omzet',
				salesRevenueInvoiced: 'Gefactureerde omzet'
			},
			sales: 'Opbrengst',
			purchase: 'Kosten',
			cubicMeters: 'm3',
			tons: 'ton',
			tooltip: {
				detailsButton: 'Details ophalen'
			}
		}
	},
	unregisteredVessels: {
		title: 'Maak of wijzig een ongeregistreerd schip',
		create: 'Maak een ongeregistreerd schip aan',
		noUnregisteredVessels: 'U heeft nog geen schepen geregistreerd.',
		searchHint: 'Vul minimaal 3 karakters in om te zoeken.',
		deleted: 'Het schip is verwijderd.',
		tabs: {
			company: 'Bedrijf',
			vessel: 'Schip',
			details: 'Details',
			preferences: 'Voorkeuren',
			files: 'Bestanden'
		}
	},
	registeredVessels: {
		title: 'Een geregistreerd schip bewerken'
	},
	vessels: {
		documents: {
			expiration: {
				title: 'Wijzig verloopdatum',
				date: 'Verloopdatum',
				placeholder: 'Geen verloopdatum'
			},
			valid_until: 'Geldig tot',
			editExpiry: 'Wijzig verloopdatum'
		},
		addFile: 'Voeg bestand toe',
		templates: {
			measurementCertificate: 'Download meetbrief template',
			bargingLetter: 'Download beunbrief template'
		}
	},
	clients: {
		noData: 'U heeft nog geen opdrachtgevers aangemaakt.',
		create: 'Maak een klant aan',
		deleted: 'De klant is verwijderd.',
		search: 'Zoek op naam of plaats...'
	},
	brokers: {
		noData: 'U heeft nog geen bevrachters/rederijen geregistreerd.',
		create: 'Maak een bevrachter aan',
		deleted: 'De bevrachter is verwijderd.',
		search: 'Zoek op naam of plaats...'
	},
	intermodal_transport: {
		title: 'Multi-modaal transport',
		actions: {
			submit: 'Aanbieden',
			'show-route-details': 'Toon route mogelijkheden',
			locations: 'Locaties configureren'
		},
		clean_slate: {
			headline: 'Lidmaatschap nodig',
			request: 'Wil je individuele containers vervoeren middels verschillende vervoersmiddelen?',
			contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
			contact_details: 'info@4shipping.com of +31 85 40 189 40.'
		},
		container: {
			title: 'Vracht details',
			equipmentType: 'Equipment type',
			description: 'Vracht omschrijving',
			requirements: 'Vereisten',
			unCode: {
				label: 'UN Code',
				hint: 'UN code moet uit 4 cijfers bestaan'
			},
			grossWeight: 'Bruto gewicht',
			emptyWeight: 'Leeggewicht',
			teuValue: 'Aantal TEU',
			additionalDetails: 'Overige details'
		},
		pickup: {
			title: 'Ophaallocatie',
			fromDate: 'Ophalen vanaf',
			toDate: 'Ophalen tot'
		},
		drop: {
			title: 'Afleverlocatie',
			fromDate: 'Afleveren vanaf',
			toDate: 'Afleveren tot'
		},
		price: {
			title: 'Prijs',
			targetPrice: 'Prijsdoel',
			publishEndDate: 'Publiceer tot',
			modality: {
				label: 'Modaliteit',
				vessel: 'Schip',
				train: 'Trein',
				truck: 'Vrachtwagen'
			}
		}
	},
	clientCreator: {
		title: 'Klant aanmaken',
		title_edit: 'Klant aanpassen',
		submit: 'Maak aan',
		save: 'Pas aan',
		created_succesfully: 'De klant is succesvol aangemaakt.',
		saved: 'De klant is succesvol aangepast.'
	},
	brokerCreator: {
		title: 'Bevrachter aanmaken',
		title_edit: 'Bevrachter aanpassen',
		submit: 'Maak aan',
		save: 'Pas aan',
		created_succesfully: 'De bevrachter is succesvol aangemaakt.',
		saved: 'De bevrachter is succesvol aangepast.'
	},
	units: {
		hours: 'uur',
		days: 'dagen',
		shifts: 'dienst',
		cubic: 'kuub',
		ton: 'ton'
	},
	filterDialog: {
		selectFilter: 'Zoeken',
		commitFilter: 'Filter',
		user: 'Gebruiker'
	},
	generatorDialog: {
		title: 'Documenten bijvoegen en aanpassingen aan factuur',
		cancel: 'Annuleren',
		generate: 'Genereren',
		invoice_number: 'Factuurnummer',
		invoice_date: 'Factuurdatum',
		combined_attachments: 'Samenvoegen',
		separate_attachments: 'Losse bestanden',
		selected_language: 'Factuur taal',
		invoiceNumberInUsed: 'Dit factuurnummer is al gebruikt.',
		fetchNewInvoiceNumber:
			'Dit factuurnummer is al gebruikt, klik op het pluspictogram om een nieuw nummer te krijgen.'
	},
	emailDialog: {
		attachment: 'Bijlage',
		charterBody:
			'Beste {firstName},{newline}' +
			'{newline}' +
			'Er is een transport aangemaakt voor het vervoer van {cargoType}.{newline}' +
			'De charter is toegevoegd als bijlage. Deze charter is een bevestiging van de gemaakte afspraken, als er iets niet klopt neem dan contact op.{newline}' +
			'{newline}' +
			'Hierbij bevestigen we het volgende transport:{newline}' +
			'Product: {cargoType}{newline}' +
			'Hoeveelheid: {quantity}{newline}' +
			'Scheepsnaam: {shipName} ({dimensions}){newline}' +
			'Schipper: {skipperName}{newline}' +
			'Tel: {phoneNumber}{newline}' +
			'Laadlocatie: {startTerminal}{newline}' +
			'Laden: {loadingTerm}{newline}' +
			'Loslocatie: {endTerminal}{newline}' +
			'Lossen: {unloadingTerm}{newline}' +
			'{newline}' +
			'Vriendelijke groeten,{newline}' +
			'{senderFullName}{newline}' +
			'{newline}' +
			'{senderSignature}',
		charterSend: 'Charter versturen',
		charterSubject: 'Aan de reis. Bekijk de charter! ({dossierNumber})',
		charterTitle: 'Bericht naar schipper',
		charterTitleBroker: 'Bericht naar bevrachter',
		copyMe: 'Stuur mij een kopie',
		from: 'Naam afzender',
		invalid_email: '{email} is geen geldig e-mailadres',
		invoiceBody:
			'Beste {firstName},{newline}' +
			'{newline}' +
			'{newline}' +
			'De factuur is toegevoegd als bijlage.{newline}' +
			'{newline}' +
			'Vriendelijke groeten,{newline}' +
			'{senderFullName}{newline}' +
			'{newline}' +
			'{senderSignature}',
		invoiceSend: 'Factuur versturen',
		invoiceSubject: 'Factuur nr. {invoiceNumber} ({invoiceRecipient})',
		invoiceTitle: 'Bericht naar klant',
		message: 'Email bericht',
		orderBody:
			'Beste relatie,{newline}{newline}' +
			'Hierbij bevestigen we het volgende transport:{newline}' +
			'Product: {cargoType}{newline}' +
			'Hoeveelheid: {quantity}{newline}' +
			'Scheepsnaam: {shipName} ({dimensions}){newline}' +
			'Schipper: {skipperName}{newline}' +
			'Tel: {phoneNumber}{newline}' +
			'Laadlocatie: {startTerminal}{newline}' +
			'Laden: {loadingTerm}{newline}' +
			'Loslocatie: {endTerminal}{newline}' +
			'Lossen: {unloadingTerm}{newline}' +
			'Uw referentienummer: {clientOrderReference}{newline}' +
			'{newline}' +
			'Vriendelijke groeten,{newline}' +
			'{senderFullName}{newline}' +
			'{newline}' +
			'{senderSignature}',
		orderSend: 'Opdrachtbevestiging versturen',
		orderSubject: 'Opdrachtbevestiging ({dossierNumber})',
		orderTitle: 'Bericht naar opdrachtgever',
		quotationBody:
			'Beste relatie,{newline}{newline}' +
			'Hierbij sturen we u de offerte van het volgende transport:{newline}' +
			'Product: {cargoType}{newline}' +
			'Hoeveelheid: {quantity}{newline}' +
			'Laadlocatie: {startTerminal}{newline}' +
			'Laden: {loadingTerm}{newline}' +
			'Loslocatie: {endTerminal}{newline}' +
			'Lossen: {unloadingTerm}{newline}' +
			'Uw referentienummer: {clientOrderReference}{newline}' +
			'{newline}' +
			'Vriendelijke groeten,{newline}' +
			'{senderFullName}{newline}' +
			'{newline}' +
			'{senderSignature}',
		quotationSend: 'Offerte versturen',
		quotationSubject: 'Offerte',
		quotationTitle: 'Bericht naar opdrachtgever',
		receivers: 'Ontvanger(s)',
		replyTo: 'Antwoorden aan',
		send: 'Versturen',
		sendAndFinalise: 'Versturen en inplannen',
		cancel: 'Annuleren',
		subject: 'Onderwerp',
		title: 'Verstuur',
		title_charter: 'Verstuur charter',
		title_charter_order: 'Verstuur charter & opdrachtbevestiging',
		title_order: 'Verstuur opdrachtbevestiging',
		title_quotation: 'Verstuur offerte'
	},
	invoiceCreator: {
		companyType: {
			label: 'Relatie',
			client: 'Klant',
			broker: 'Bevrachter',
			supplier: 'Leverancier',
			unregistered_terminal: 'Ongeregistreerde terminal',
			registered_terminal: 'Geregistreerde terminal',
			unregistered_vessel: 'Ongeregistreerd schip',
			registered_vessel: 'Geregistreerd schip',
			external_unregistered_vessel: 'Extern ongeregistreerd schip',
			unknown: 'Onbekend',
			otherOptions: 'Niet filteren op relatie',
			allCustomers: 'Alle bedrijfstypen',
			hint: 'Filter de financiële regels op gekoppelde relatie.'
		},
		subject: {
			sales: 'verkoopfactuur',
			purchase: 'inkoopfactuur',
			unknown: 'factuur'
		},
		sales: 'Verkoop',
		purchase: 'Inkoop',
		title: '{subject} maken',
		title_external: 'Externe {subject} registreren',
		title_edit: '{subject} bewerken',
		title_edit_external: 'Externe {subject} bewerken',
		sub_title: 'Vul de factuurregels in om een factuur aan te maken.',
		sub_title_external: 'Vul de factuurregels in om een externe factuur te registreren.',
		sub_title_combined_sales: 'Maak een gecombineerde factuur voor een zelfde klant.',
		sub_title_combined_purchase:
			'Maak een gecombineerde self-billing factuur voor de kosten. Standaard gaan we ervan uit dat deze factuur voor de transporteur is.',
		sub_title_warning: 'Niet alle klanten of schippers zijn hetzelfde. ',
		review: 'Factuur beoordelen',
		journal: 'Verkoopboek',
		creditor: 'Crediteur',
		debtor: 'Debiteur',
		account_placeholder: 'Selecteer klant of schip',
		search: 'Vul een zoekterm in om te zoeken',
		search_more_characters: 'Vul minimaal 3 karakters in om te zoeken',
		search_no_data: 'Uw zoekterm heeft geen resultaten opgeleverd',
		search_busy: 'Aan het zoeken...',
		reference: 'Uw referentie',
		reference_short: 'Referentie',
		description: 'Algemene opmerking',
		product_number: 'Product/dienst',
		product_description: 'Omschrijving',
		clientOrderReference: '(Inkoop) referentie:',
		cargo_comments: 'Opmerking',
		cargo_amount: 'Aantal',
		cargo_price: 'Prijs',
		cargo_price_total: 'Totaal',
		dependent_on: 'Over',
		quantity_percentage: 'Percentage',
		term_of_payment: 'Betalingstermijn',
		invoice_date: 'Factuurdatum',
		invoice_number: 'Factuurnummer',
		invoice_for: 'Factuur voor',
		invoice_of: 'Factuur van',
		vat: 'BTW',
		days: 'dagen',
		status: 'Zet status direct op:',
		close_dossier: "Zet dossier status op 'afgesloten'",
		depend_quantity_on_cargo_quantity: 'Koppel hoeveelheid aan vrachtvolume',
		depend_quantity_on_cargo_quantity_transshipment_direction: {
			loading: 'Laden',
			unloading: 'Lossen'
		},
		unsync_with_financial_registration_message: 'Er is een verschil met de financiele registratie!',
		sync_financial_registration: 'Update registratie automatisch',
		actions: {
			edit: 'Aanpassen',
			send: 'Verzenden',
			store: 'Aanmaken',
			cancel: 'Annuleren',
			submit: 'Toon voorbeeld',
			close: 'Sluiten',
			ready: 'Klaar',
			addAttachments: 'Opslaan + bijlage toevoegen'
		},
		attachments: {
			title: 'Bijlagen toevoegen',
			subtitle: 'Bijlage(n)'
		}
	},
	genericSelector: {
		search: 'Vul een zoekterm in om te zoeken',
		search_more_characters: 'Vul minimaal 3 karakters in om te zoeken',
		search_no_data: 'Uw zoekterm heeft geen resultaten opgeleverd',
		search_busy: 'Aan het zoeken...'
	},
	customerSelector: {
		placeholder: 'Selecteer een relatie',
		label: 'Relatie'
	},
	productSelector: {
		placeholder: 'Selecteer een product/dienst',
		label: 'Product/dienst'
	},
	authorizationDialog: {
		authorize: 'Autoriseren',
		click: 'Klik a.u.b.',
		here: 'hier',
		exact: {
			toAuthorize: 'om in te loggen met Exact-Online.'
		},
		labels: {
			notFound: 'Er is geen actieve connectie gevonden.',
			expired: 'Uw connectie is verlopen.'
		}
	},
	externalInvoiceDownload: {
		title: 'Externe factuur downloaden',
		attachments: {
			subtitle: 'Factuur bijlage(n)',
			by: 'door',
			empty: 'Geen facturen toegevoegd aan externe factuur of transport.'
		},
		actions: {
			close: 'Sluiten'
		}
	},
	invoices: {
		deleted: 'De factuur is verwijderd.',
		credited: 'De factuur is gecrediteerd, u kunt deze aanpassen of versturen.'
	},
	documentGenerator: {
		loading: 'De gegevens worden opgehaald.',
		errorWhileLoading: 'Er is een fout opgetreden tijdens het ophalen van de gegevens. Probeer het later opnieuw.',
		billOfLading: {
			receiverTextForSkipper: 'De klantgegevens worden automatisch toegevoegd op het document',
			loadingBillReceiptNumber: 'Laadbon nummer',
			title_loading: 'Genereer laadbon bouwmaterialen',
			title_unloading: 'Genereer losbon bouwmaterialen',
			receiver: 'Ontvanger',
			shipper: 'Leverancier',
			generatedAt: 'Uitgiftedatum',
			issueLocation: 'Uitgifteplaats',
			general: 'Algemeen',
			vessel: 'Beladen vaartuig',
			skipperName: 'Naam schipper',
			destination: 'Loslocatie',
			extractionArea: 'Wingebied',
			delivery_method: {
				label: 'Leverwijze',
				franco_on_board: 'Franco aan boord',
				franco_on_shore: 'Franco voor de wal',
				franco_at_work: 'Franco op werk',
				franco_on_truck: 'Franco op vrachtwagen',
				franco_underwater: 'Franco onder water'
			},
			cargo: 'Product(en)/vrachtbepaling',
			startTerminal: 'Laadlocatie',
			extractor: 'Winzuiger',
			holdDimensions: 'Hoeveelheid 0-lijn',
			holdDimensionsOpen: 'Kleppen open',
			holdDimensionsClosed: 'Kleppen dicht',
			product: 'Product',
			quantity: 'Hoeveelheid',
			quantityUnits: {
				tonnes: 'ton',
				cubic: 'kuub',
				cubic_meters: 'kuub'
			},
			desalination: 'Ontziltingsproces',
			sandIsRinsed: 'Zand gespoeld',
			desalinationMethods: {
				submerged: 'Volledig onder water',
				hoses: 'Met slang(en)'
			},
			desalinationWaterUsed: 'Waterverbruik',
			measuredSaltContent: 'Gemeten zoutgehalte',
			measuredSaltContentFrontHopper: 'Gemeten zoutgehalte voorbeun',
			measuredSaltContentRearHopper: 'Gemeten zoutgehalte achterbeun',
			dryPumpUsed: 'Droog gepompt',
			dryPumpHours: 'Pomp tijd',
			loadingPeriodFrom: 'Starttijdstip laden',
			loadingPeriodUntil: 'Eindtijdstip laden',
			unloadingPeriodFrom: 'Starttijdstip lossen',
			unloadingPeriodUntil: 'Eindtijdstip lossen',
			rinsePeriodFrom: 'Starttijdstip spoelen',
			rinsePeriodUntil: 'Eindtijdstip spoelen',
			certificates: 'Certificaten/regelgeving',
			certificateType: 'Certificaat',
			'certificate-bsb': 'BSB',
			'certificate-benor': 'Benor',
			'certificate-no_bsb_benor': 'Geen',
			noBsbOrBenor: 'Geen',
			certificateMarking: 'Bedrijfskenmerk',
			licenseHolder: 'Vergunninghouder',
			deedNumber: 'Aktenummer',
			certificationIsCE: 'CE gecertificeerd',
			certificationIsDOP: 'DOP',
			certificationCEMarking: 'CE kenmerk',
			certificationDOPMarking: 'DOP kenmerk',
			certificationBRLMarking: 'BRL kenmerk',
			skipperDeclareCleanHold: 'Schipper verklaart een schoon laadruim',
			certificationBenorRevision: 'Rev.',
			certificationBenorCertificateNumber: 'Cert. nr.',
			certificationClaim: 'Certificaatnummer',
			certificateHolder: 'Certificaathouder',
			environmentalQuality: 'Milieukwaliteit',
			applicabilityClaim: 'Toepasbaarheid',
			remark: 'Opmerkingen',
			submit: 'Genereer',
			transportWillBeCreated: 'Voor deze laadbon zal een transport worden aangemaakt'
		},
		billOfLadingTransport: {
			title: 'Genereer transport cognossement',
			carrier: 'Vervoerder',
			shipper: 'Afzender',
			generatedAt: 'Uitgiftedatum',
			issueLocation: 'Uitgifteplaats',
			subcontractedCarrier: '(Onder)vervoerder',
			vessel: 'Schip',
			companyName: 'Bedrijf',
			vesselName: 'Scheepsnaam',
			euNumber: 'ENI nummer',
			skipperName: 'Naam schipper',
			destination: 'Loslocatie',
			cargo: 'Product(en)/vrachtbepaling',
			transport: 'Transport',
			startTerminal: 'Laadlocatie',
			product: 'Product',
			quantity: 'Hoeveelheid',
			quantityUnits: {
				tonnes: 'ton',
				cubic: 'kuub'
			},
			declarationShipper: 'Verklaring afzender',
			instructionsShipper: 'Instructies afzender',
			other: 'Overig',
			remark: 'Opmerkingen',
			selectedLanguage: 'Document taal',
			submit: 'Genereer'
		},
		advising: {
			title: 'Genereer transport document',
			comments: 'Opmerkingen',
			selectedLanguage: 'Document taal'
		},
		lciReport: {
			title: 'Genereer LCI rapport',
			lastTransportedCargos: 'Laatst vervoerde ladingen',
			skipper: 'Schipper van de {vesselName}',
			skipperName: 'Schipper',
			client: 'Ontvangen & Opdrachtgever',
			locations: 'Locaties',
			startLocation: 'Laadlocatie',
			endLocation: 'Loslocatie',
			submit: 'Genereer'
		},
		hopperMeasurementsReport: {
			title: 'Genereer beunmeting document',
			hopper: 'Beun',
			frontHopper: 'Voorbeun',
			rearHopper: 'Achterbeun',
			frontHopperDocument: 'Beunbrief voorbeun',
			rearHopperDocument: 'Beunbrief achterbeun',
			starboard: 'Stuurboord',
			port: 'Bakboord',
			explanation: 'Als de lading boven de den uitkomt vul je een negatief getal in voor het aantal centimeters.',
			wheelhouse: 'Stuurhut',
			hopperAmount: {
				single: 'Één beun',
				double: 'Twee beunen'
			},
			total: 'Totaal',
			measurementInCm: 'In cm',
			loading: 'De beunbrief wordt opgehaald',
			noBargingLetter: 'Er is geen beunbrief gekoppeld aan dit schip, voeg eerst een beunbrief document toe.',
			noBargingLetterAuthorization: 'U heeft geen rechten om de beunbrief te bekijken'
		}
	},
	vesselContactCard: {
		title: 'Contactkaart',
		tabs: {
			vessel: 'Schip',
			company: 'Bedrijf',
			preferences: 'Voorkeuren'
		},
		vessel: {
			name: 'Scheepsnaam',
			euNumber: 'ENI nummer',
			euNumberSuffix: 'Europa nummer',
			dimensions: 'Afmetingen',
			tonnage: 'Tonnage',
			draught: 'Diepgang',
			sustainability: 'Duurzaamheid',
			hold: 'Ruim',
			certificates: 'Certificaten/vergunningen',
			insurance: 'Verzekering',
			email: 'Emailadres',
			phoneNumber: 'Telefoonnummer',
			skipperName: 'Schipper',
			mmsi: 'MMSI',
			general: 'Overige'
		},
		company: {
			name: 'Bedrijfsnaam',
			phone: 'Telefoonnummer',
			email: 'Emailadres',
			website: 'Website',
			address: 'Adres (bezoek)',
			postalCode: 'Postcode',
			place: 'Plaats',
			coc: 'KvK/Ondernemings nr',
			vat: 'BTW nr',
			postalAddress: 'Adres (post)',
			invoiceAddress: 'Adres (facturen)'
		},
		fileName: 'Contactkaart-{vesselName}.pdf',
		actions: {
			close: 'Sluiten',
			download: 'Download contactkaart'
		}
	},
	loading_bill: {
		title: 'Laadbon maken',
		clean_slate: {
			headline: 'Lidmaatschap nodig',
			request: 'Wil je laadbonnen maken voor het vervoeren van bouwmaterialen?',
			contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
			contact_details: 'info@4shipping.com of +31 85 40 189 40.'
		},
		empty: {
			headline: 'Maak uw eerste laadbon',
			description:
				'Op deze pagina ziet u straks uw aangemaakte laadbonnen. Klik op de button om het formulier te openen en een laadbon aan te maken.'
		},
		new: {
			title: 'Nieuwe laadbon'
		},
		success: 'De laadbon is succesvol aangemaakt en verstuurd naar de schipper.',
		actions: {
			submit: 'Genereer + verstuur',
			new: 'Nieuwe laadbon'
		},
		header: {
			title: 'Laadbonnen'
		}
	},
	invoicing: {
		clean_slate: {
			headline: 'Lidmaatschap nodig',
			request: 'Wil je facturen aanmaken en versturen via 4Shipping?',
			contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
			contact_details: 'info@4shipping.com of +31 85 40 189 40.'
		},
		no_connection: {
			headline: 'Deze module is nog niet geactiveerd.',
			details: "Ga naar uw profiel en onder het kopje 'Koppelingen' kan je deze module activeren.",
			button: 'Klik hier om naar de koppelingen te gaan.'
		},
		toolbar: {
			add: {
				sales: 'Verkoopfactuur',
				purchase: 'Inkoopfactuur'
			},
			toggle: {
				sales: 'Verkoop',
				purchase: 'Inkoop'
			}
		},
		showSent: 'Toon verzonden',
		headline: 'Facturatie',
		table: {
			filters: {
				dossier_number: 'Zoeken op dossiernummer...',
				invoice_number: 'Zoeken op factuurnummer...',
				client_order_reference: 'Zoeken op referentie...'
			},
			headers: {
				invoiceNumber: 'Factuurnummer',
				dossierNumber: 'Dossiernummer',
				vesselName: 'Scheepsnaam',
				unloadingDate: 'Losdatum',
				product: 'Product',
				customer: 'Relatie (nr)',
				reference: 'Relatie ref.',
				price: 'Prijs',
				status: 'Status'
			},
			status: {
				open: 'Concept / geregistreerd',
				generated: 'Aangemaakt / akkoord voor betaling',
				sent: 'Verzonden',
				paid: 'Betaald',
				closed: 'Gesloten'
			},
			actions: {
				edit: 'Bewerk factuur',
				remove: 'Verwijder factuur',
				generate: 'Genereer PDF',
				download: 'Download factuur',
				mail: 'Verstuur factuur',
				credit: 'Crediteer factuur'
			},
			notifications: {
				saveStatus: {
					success: 'De status is succesvol gewijzigd',
					error: 'De status kan niet worden gewijzigd, probeer het later nog eens.'
				}
			}
		},
		send: {
			title: 'Factuur versturen',
			forward: 'Deze factuur wordt ook gestuurd naar {email}.',
			last_sent: 'Laatst verzonden:',
			to: 'Aan:',
			hasFailingEmails: 'Niet alle e-mails zijn succesvol verzonden.'
		},
		status: {
			open: 'Concept factuur',
			open_external: 'Factuur geregistreerd',
			generated: 'Factuur aangemaakt',
			generated_external: 'Akkoord voor betaling',
			sent: 'Verzonden',
			paid: 'Betaald',
			paid_external: 'Betaald',
			closed: 'Gesloten'
		}
	},
	'terminal-management': {
		clean_slate: {
			headline: 'Lidmaatschap nodig',
			request: 'Wil je je terminals beheren en terminal bezoeken plannen via 4Shipping?',
			contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
			contact_details: 'info@4shipping.com of +31 85 40 189 40.'
		}
	},
	'capacity-radar-management': {
		clean_slate: {
			headline: 'Lidmaatschap nodig',
			request: 'Wil je je capaciteitsradar beheren via 4Shipping?',
			contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
			contact_details: 'info@4shipping.com of +31 85 40 189 40.'
		}
	},
	'depot-management': {
		clean_slate: {
			headline: 'Lidmaatschap nodig',
			request: 'Wil je je depot beheren via 4Shipping?',
			contact_us: 'Neem dan contact met ons op voor een kosteloze demo!',
			contact_details: 'info@4shipping.com of +31 85 40 189 40.'
		}
	},
	preparePurchaseOrder: {
		title: 'Inkooporder voorbereiden',
		companyType: {
			label: 'Type relatie',
			client: 'Klant',
			broker: 'Bevrachter',
			supplier: 'Leverancier',
			unregistered_terminal: 'Ongeregistreerde terminal',
			registered_terminal: 'Geregistreerde terminal',
			unregistered_vessel: 'Ongeregistreerd schip',
			registered_vessel: 'Geregistreerd schip',
			unknown: 'Onbekend',
			otherOptions: 'Niet filteren op relatie',
			allCustomers: 'Alle bedrijfstypen',
			hint: 'Filter de transporten op gekoppelde relatie.'
		},
		toggle: {
			revenue: 'Inkomsten',
			cost: 'Uitgaven'
		},
		description: 'Maak een export van de productleveringen incl. prijsregels van de geselecteerde dossiers.',
		selectedTransports: 'Geselecteerde transporten',
		clientOrderReference: {
			label: 'Inkoopreferentie (optioneel)',
			hint: 'Als je de bedrijfsrelatie selecteert, wordt het inkoopordernummer van de financiële registraties van de geselecteerde transporten overschreven.',
			warning: 'Deze financiële registratie had al een PO-nummer.'
		},
		submit: {
			success: 'De export is gedownload.',
			success_without_download: 'Het PO-nummer is succesvol gekoppeld.',
			error: 'Er is iets fout gegaan bij het aanmaken van de export, probeer het later nogmaals.'
		},
		actions: {
			close: 'Sluiten',
			export: 'Export downloaden',
			saveExport: 'Opslaan + export downloaden',
			onlySave: 'Alleen PO-nummer opslaan'
		}
	},
	downloadDocuments: {
		description: 'Kies de bestandstypes om te downloaden voor de geselecteerde transporten.',

		actions: {
			close: 'Sluiten',
			download: 'Downloaden'
		}
	}
}
